<template>
  <div class="v" :class="dependRetailFormCode ? '' : 'app-container'">
    <div class="head-container" v-if="!dependRetailFormCode">
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" clearable @change="toQuery" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" clearable @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="采购开始日期" end-placeholder="采购结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-select v-model="query.statusList" class="filter-item" placeholder="状态" clearable @change="toQuery" style="width: 170px" multiple collapse-tags>
        <el-option v-for="(o, k) in status" :key="k" :value="k" :label="o.label" />
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="输入采购单号或ERP单号搜索" @keypress.enter.native="toQuery" clearable style="width: 220px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">导出</el-button>
      <el-button type="primary" class="filter-item" icon="el-icon-plus" @click="add" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_ADD_EDIT'])">新建采购单</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" border height="200px">
      <el-table-column prop="formCode" label="采购单号" width="120" />
      <el-table-column label="下单账户" width="110" prop="buyerErpId" v-if="!dependRetailFormCode" />
      <el-table-column label="总金额" width="120" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price((scope.row.totalMoney || 0) - (scope.row.discountAmount || 0))}}</template>
      </el-table-column>
      <el-table-column label="支付方式" width="80" :formatter="r => {return payTypes[r.payType];}" />
      <el-table-column prop="purchaseTime" label="采购时间" width="150" :formatter="r => { return new Date(r.purchaseTime).format(); }" />
      <!-- <el-table-column prop="sellerName" label="供应商" width="120" show-overflow-tooltip /> -->
      <el-table-column label="收货地址" min-width="180" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recCity}}{{scope.row.recDistrict}}{{scope.row.recAddress}}</template>
      </el-table-column>
      <el-table-column prop="info" label="摘要" min-width="100" show-overflow-tooltip />
      <el-table-column label="付款情况" align="center" width="100" fixed="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_PAY'])">
        <template slot-scope="scope">
          <el-tag :type="payStatus[scope.row.payType][scope.row.payStatus].type" :key="scope.row.id">{{payStatus[scope.row.payType][scope.row.payStatus].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作来源" prop="isMiniProgram" width="100" sortable="custom">
        <template slot-scope="scope">{{scope.row.isMiniProgram?"小程序":"电脑端"}}</template>
      </el-table-column>
      <el-table-column label="状态" align="center" width="100" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type" :key="scope.row.id">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50px" fixed="right" v-if="dependRetailFormCode">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>
      </el-table-column>
      <el-table-column width="100px" fixed="right" v-else>
        <div class="row-commands" slot-scope="scope">
          <template v-if="checkPermission(['PURCHASE_ALL','PURCHASE_ADD_EDIT'])">
            <el-button size="mini" type="text" @click="edit(scope.row)" v-if="scope.row.status === 0">编辑</el-button>
          </template>
          <el-button size="mini" type="text" @click="edit(scope.row)" v-if="scope.row.status != 0">查看</el-button>
          <el-dropdown>
            <el-button type="text" size="mini">
              更多
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu>
              <el-dropdown-item v-permission="['PURCHASE_ALL','PURCHASE_PAY']" @click.native="pay(scope.row)" v-if="checkPayable(scope.row)">付款情况</el-dropdown-item>
              <el-dropdown-item @click.native="edit(scope.row, 'copy')" v-if="scope.row.status !== 0">再次采购</el-dropdown-item>
              <el-dropdown-item v-permission="['PURCHASE_ORDER_RESET']" @click.native="cancel(scope.row)" v-if="scope.row.status === 1">取消</el-dropdown-item>
              <el-dropdown-item divided @click.native="print(scope.row.id)">打印</el-dropdown-item>
              <el-dropdown-item divided @click.native="subDelete(scope.row)" v-if="scope.row.status === 0">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="editor.show" :title="editor.title" custom-class="custom-dialog-max-width" @closed="handleEditorClosed">
      <e-form ref="form" :title.sync="editor.title" :depend-retail-form-code="dependRetailFormCode" @submit="init" @close="editor.show = false" @dopay="pay" />
    </el-dialog>

    <consignment-order ref="consignmentOrder" />
    <inbound-form ref="inboundForm" />
    <pays ref="pays" @paied="toQuery" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form-view";
import inboundForm from "../../warehouse/inbound/form";
import pays from "./pays";
import consignmentOrder from "./consignment-order";
import { del } from "@/api/purchaseOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import { close as closeOrder } from "@/api/distributorOrder";

export default {
  components: { eForm, inboundForm, consignmentOrder, pays },
  mixins: [initData],
  props: {
    dependRetailFormCode: Array,
  },
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      editor: {
        show: false,
        title: "",
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "已提交",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "warning",
          label: "待发货",
        },
        {
          type: "success",
          label: "部分已发货",
        },
        {
          type: "success",
          label: "已发货",
        },
        {
          type: "success",
          label: "部分已收货",
        },
        {
          type: "success",
          label: "已完成",
        },
        {
          type: "info",
          label: "已关闭",
        },
        {
          type: "info",
          label: "已取消",
        },
        {
          type: "info",
          label: "部分已关闭",
        },
      ],
      query: {
        sellerId: null,
        dateRange: null,
        status: null,
        formCode: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      if (this.dependRetailFormCode && this.dependRetailFormCode.length === 0) return false;
      this.url = "api/purchaseForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      if (this.dependRetailFormCode && this.dependRetailFormCode.length) {
        query.formCodes = this.dependRetailFormCode;
      }
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "purchaseTime,desc" },
        query
      );
      return true;
    },
    subDelete(data) {
      this.$confirm("确定删除采购单【" + data.formCode + "】吗？", "操作确认", {
        type: "warning",
      }).then(() => {
        del(data.id).then((res) => {
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        });
      });
    },
    checkPayable(row) {
      if (row) {
        if (row.payType === 1) return [0, 1, 3].indexOf(row.status) < 0;
        else return row.status !== 3;
      }
      return false;
    },
    handleEditorClosed() {
      this.$refs.form && this.$refs.form.clear();
    },
    add() {
      this.editor.show = true;
      this.$nextTick((_) => {
        this.$refs.form && this.$refs.form.resetForm();
      });
    },
    edit(data, action = null) {
      this.editor.show = true;
      this.$nextTick((_) => {
        this.$refs.form && this.$refs.form.resetForm(data.id, action);
      });
    },
    inbound(data) {
      this.$refs.consignmentOrder &&
        this.$refs.consignmentOrder.resetForm(data);
    },
    pay(data) {
      if (data) {
        this.$refs.pays && this.$refs.pays.show(data);
      }
    },
    cancel(data) {
      this.$confirm(
        "您确定要取消订单【" + data.formCode + "】吗？",
        "操作确认",
        { type: "warning" }
      ).then((_) => {
        closeOrder(data.id).then((_) => {
          this.$notify({
            title: "取消采购单成功",
            type: "success",
            duration: 2500,
          });
          data.status = 10;
        });
      });
    },
    print(id) {
      this.$print("purchase", id);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/purchase/download", this.params)
        .then((result) => {
          downloadFile(result, "采购明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 10px;
}
.el-icon-arrow-down {
  font-size: 10px;
}
</style>