<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" title="确认" :close-on-click-modal="false" @closed="cancel" width="560px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="66px" v-if="form" @submit.native.prevent>
        <el-form-item label="数量" prop="cancelCount">
          <el-input-number v-model="form.cancelCount" :min="0" :max="form.quantity -form._cancelCount" :step="1" :precision="1" style="width:100%;" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave" :disabled="form.cancelCount==0">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        id: null,
        cancelCount: null,
        quantity: null,
        _cancelCount: null,
      },
      rules: {
        cancelCount: [{ required: "true", message: "请输入数量" }],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    doSave() {
      this.loading = true;
      let data = {
        id: this.form.id,
        cancelCount: this.form.cancelCount,
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          request({
            url: "api/shop/form/closeRow/after",
            method: "post",
            data,
          })
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    resetForm(form) {
      this.dialog = true;
      this.disabled = false;
      if (form) {
        this.form = {
          id: form.id,
          cancelCount: form.quantity - form.cancelCount,
          quantity: form.quantity,
          _cancelCount: form.cancelCount,
        };
      } else {
        this.form = {
          id: null,
          cancelCount: null,
          quantity: null,
          _cancelCount: null,
        };
      }
    },
  },
};
</script>

