<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="1280px" @closed="handleClosed">
    <template v-if="form">
      <template v-if="editable && !targetDependFormCode">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="right" label-suffix=":">
          <div class="h">
            <!-- <el-form-item label="出库库房" v-show="lockWarehouse">
              <div style="width: 180px;">{{form.warehouseName || "请输入调拨单号"}}</div>
            </el-form-item>-->
            <el-form-item label="出库库房" prop="warehouseId">
              <el-input :value="form.warehouseName" readonly style="width: 180px;" v-if="form.id" />
              <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName" auto-select-single-option url="api/warehouse" filterable clearable placeholder="请选择库房" style="width: 180px;" v-else />
            </el-form-item>
            <el-form-item label="出库时间" prop="formTime">
              <el-date-picker v-model="form.formTime" :clearable="false" value-format="timestamp" style="width: 180px;" />
              <!-- <el-input readonly :value="new Date(form.formTime).format('yyyy-MM-dd')" style="width: 180px;" /> -->
            </el-form-item>
            <el-form-item prop="manager" label="出库人">
              <el-input v-model="form.manager" :maxlength="25" style="width: 180px;" />
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="dependFormCode" label="发货通知单" v-if="form.formType === 8">
              <el-input :value="form.dependFormCode" readonly style="width: 180px;" v-if="form.id" />
              <div class="h" style="width: 460px" v-else>
                <datagrid-picker reference="选择发货通知单" reference-type="info" url="api/shop/deliveryNotice" :params="{statusList: ['confirm','partOut'],sort:'formCode,desc'}" :query-define="formCodeDefine" :popper-width="800" :show-index="false" @change="handleDependFormCodeAdd">
                  <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                    <el-input :maxlength="20" v-model="formCodeDefine.code" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入发货单号/零售单号/合同号" style="width: 300px;">
                      <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                    </el-input>
                  </div>
                  <el-table-column prop="formCode" label="发货单号" width="110" fixed />
                  <el-table-column label="单据日期" width="100" :formatter="r => { return new Date(r.formTime).format('yyyy/MM/dd')}" />
                  <el-table-column label="零售单号" prop="dependFormCode" width="120" />
                  <el-table-column label="合同号" prop="contractNo" width="120" />
                  <el-table-column prop="customerName" label="收货人" width="100" />
                  <el-table-column prop="customerPhone" label="联系电话" width="100" />
                  <el-table-column label="收货地址" min-width="200" :show-overflow-tooltip="true">
                    <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.customerAddress}}</template>
                  </el-table-column>
                  <el-table-column label="发货仓库" width="130" prop="warehouseName" />
                  <el-table-column prop="totalMoney" label="金额" width="120" align="right" :formatter="$price" />
                </datagrid-picker>
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入发货通知单号查询并填入明细" class="flex" style="margin-left: 10px;" @change="loadConsignmentOrder">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="contractNo" label="合同号">
              <el-input v-model="form.contractNo" :maxlength="25" style="width: 180px;" readonly />
            </el-form-item>
          </div>
          <el-form-item prop="items" label="出库明细">
            <el-alert type="error" show-icon :closable="false" :title="`以下仅显示库房「${form.warehouseName}」的明细。`" class="lh-100" v-if="form.warehouseId && !form.id" />
            <el-table :data="filteredItems">
              <el-table-column prop="code" label="商品编码" width="130" />
              <!-- <el-table-column prop="erpCode" label="ERP编码" min-width="130" /> -->
              <el-table-column prop="goodsName" label="名称" min-width="180" show-overflow-tooltip />
              <el-table-column prop="specs" label="规格" min-width="140" :formatter="$goodsSpecConvert" show-overflow-tooltip />
              <el-table-column prop="brandName" label="品牌" width="80" show-overflow-tooltip />
              <el-table-column label="发货日期" width="85" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.deliveryDate && new Date(scope.row.deliveryDate).format('yyyy/MM/dd')}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="warehouseName" label="发货库房" width="120" />
              <el-table-column prop="_currentStock" label="当前库存" width="100" align="right" />
              <el-table-column label="出库数量" width="100" align="right">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.realCount" :min="0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row,'realCount',1)" style="width: 100%;" v-if="form.formType === 0" />
                  <span v-else>{{scope.row.realCount}}</span>
                </template>
              </el-table-column>
              <el-table-column width="60" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="info" label="备注" width="140">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" :maxlength="200" />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item prop="installer" label="安装人员">
            <el-input v-model="form.installer" :maxlength="50" style="width: 180px;"/>
          </el-form-item>
          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="3" resize="none" />
          </el-form-item>

          <div class="h c">
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <el-popover trigger="hover" placement="top-start" v-if="auditStore && auditStore.length">
              <el-table :data="auditStore" height="250">
                <el-table-column label="操作时间" :formatter="r => {return new Date(r.createAt).format(); }" width="150" />
                <el-table-column label="操作人" prop="createBy" width="120" />
                <el-table-column label="操作类型" width="100">
                  <template slot-scope="scope">{{scope.row.operateType===0?"确认出库": "反审核"}}</template>
                </el-table-column>
              </el-table>
              <el-button type="text" slot="reference">查看操作记录</el-button>
            </el-popover>
            <div class="flex"></div>
            <div slot="footer" class="dialog-footer">
              <el-button type="text" @click="dialog = false">取消</el-button>
              <el-button :loading="saving" type="primary" @click="doSave" :disabled="submiting">保存</el-button>
              <el-button :loading="submiting" type="danger" @click="doSubmit" :disabled="saving" v-if="form.id">保存并出库</el-button>
            </div>
          </div>
        </el-form>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="出库单号">{{form.formCode}}</form-info-item>
              <form-info-item label="出库库房">{{form.warehouseName}}</form-info-item>
              <form-info-item label="出库时间">{{new Date(form.formTime).format('yyyy/MM/dd')}}</form-info-item>
            </div>
            <div>
              <form-info-item label="出库方式">{{storageViewTypes[form.formType]}}</form-info-item>
              <form-info-item label="发货单号">{{form.dependFormCode}}</form-info-item>
              <form-info-item label="出库人" v-if="form.manager">{{form.manager}}</form-info-item>
            </div>
          </div>
          <el-table border :data="form.items" highlight-current-row empty-text="该订单没有出库的商品" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="goodsName" label="名称" min-width="240" />
            <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            <el-table-column prop="brandName" label="品牌" width="120" />
            <!-- <el-table-column prop="seriesName" label="系列" width="120" /> -->
            <el-table-column label="发货日期" width="85" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.deliveryDate && new Date(scope.row.deliveryDate).format('yyyy/MM/dd')}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="_currentStock" label="当前库存" width="100" align="right" />
            <el-table-column prop="realCount" label="出库数量" width="100" align="right" />
            <el-table-column prop="info" label="备注" width="100" show-overflow-tooltip />
          </el-table>
          <form-info-item label="安装人员">{{form.installer}}</form-info-item>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          <form-info-item label="签收回执" v-if="form.haveReturnDoc">
            <file-uploader :entity-id="form.fileId" folder="outbount/receive" multiple readonly empty-text="没有签收回执" />
          </form-info-item>
          <el-divider />
          <div class="h c">
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <el-popover trigger="hover" placement="top-start" v-if="auditStore && auditStore.length">
              <el-table :data="auditStore" height="250">
                <el-table-column label="操作时间" :formatter="r => {return new Date(r.createAt).format(); }" width="150" />
                <el-table-column label="操作人" prop="createBy" width="120" />
                <el-table-column label="操作类型" width="100">
                  <template slot-scope="scope">{{scope.row.operateType===0?"确认出库": "反审核"}}</template>
                </el-table-column>
              </el-table>
              <el-button type="text" slot="reference">查看操作记录</el-button>
            </el-popover>
            <div class="flex"></div>

            <el-button type="primary" @click="doPrint">打印出库单</el-button>
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  get,
  add,
  edit,
  finish,
  getByConsignmentOrder,
} from "@/api/storageManage";
import { get as getPurchaseOrder } from "@/api/purchaseOrder";
import { getGoodsById } from "@/api/warehouse";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";

export default {
  components: { skuSelector },
  props: {
    targetDependFormCode: String,
  },
  data() {
    return {
      loading: false,
      referenceLoading: false, //参考单据加载（如加载发货通知单、退货单等）
      saving: false,
      submiting: false,
      searching: false,
      dialog: false,
      mode: null,
      auditStore: null,
      formCodeDefine: {
        code: "",
      },
      storageTypes: {
        2: "销售出库",
        4: "退货出库",
        // 6: "调拨出库",
        0: "其他",
      },
      storageViewTypes: {
        2: "销售出库",
        4: "退货出库",
        6: "调拨出库",
        8: "零售出库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已出库", type: "success" },
      ],
      form: null,
      rules: {
        formTime: [
          { required: true, message: "请选择出库日期", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择出库库房", trigger: "change" },
        ],
        items: [
          { required: true, message: "请填写单据或者添加出库明细" },
          { type: "array", min: 1, message: "请填写单据或者添加出库明细" },
        ],
        dependFormCode: [{ required: true, message: "请输入相应的单号" }],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑出库单"
            : "新建出库单"
          : "查看出库单";
      }
      return str;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    lockWarehouse() {
      let lock = true;
      if (this.form) {
        lock = this.form.formType === 2 || this.form.formType === 6;
      }
      return lock;
    },
    filteredItems() {
      if (this.form && this.form.warehouseId) {
        return (this.form.items || []).filter((o) => {
          return o.warehouseId === this.form.warehouseId;
        });
      }
      return this.form ? this.form.items : [];
    },
  },
  methods: {
    handleClosed() {
      this.form = null;
      this.auditStore = null;
    },
    getAuditInfo() {
      if (this.form && this.form.id) {
        request({
          url: "api/inout/log/" + this.form.id,
          method: "get",
        }).then((res) => {
          this.auditStore = res;
        });
      }
    },
    doPrint() {
      this.$print("retail-outbound", this.form.id);
    },
    handleFormTypeChange() {
      this.form.dependFormId = "";
      this.form.dependFormCode = "";
      this.form.items = [];
      if (this.form.formType === 5) {
        this.form.warehouseId = null;
        this.form.warehouseName = "";
      }
    },
    handleDependFormCodeAdd(n, o, res) {
      if (res) {
        this.form.dependFormCode = res.formCode;
        this.form.contractNo = res.contractNo;
        this.loadConsignmentOrder(res.formCode);
      }
    },
    loadConsignmentOrder(fc) {
      this.form.items = [];
      if (this.form && fc) {
        this.referenceLoading = true;
        request({
          url: "api/shop/deliveryNotice/outbound/" + fc,
          method: "get",
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.info = res.info;
              this.form.contractNo = res.contractNo;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.brandName,
                  realCount: o.quantity - o.outboundQuantity,
                  goodsCount: o.quantity - o.outboundQuantity,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.seriesName,
                  specs: o.specs,
                  code: o.code,
                  erpCode: o.erpCode,
                  dependFormCode: o.dependFormCode,
                  deliveryDate: o.deliveryDate,
                  dependFormItemId: o.id,
                  info: o.info,
                  _currentStock: 0,
                  warehouseId: o.warehouseId,
                  warehouseName: o.warehouseName,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的发货通知单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadReturnOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/orderBackForm/UnOut/" + this.form.dependFormCode,
          method: "get",
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.goodsBrandName,
                  realCount: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  specs: o.goodsSpec,
                  specs: o.goodsSpec,

                  _currentStock: 0,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的退货单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadAllocationOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/allocationForm/findByCode",
          method: "post",
          data: {
            formCode: this.form.dependFormCode,
            status: 1,
          },
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.goodsBrandName,
                  realCount: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  code: o.code,
                  erpCode: o.erpCode,
                  specs: o.goodsSpec,
                  warehouseId: o.warehouseId,
                  _currentStock: 0,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的调拨单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    findByConsignmentOrder(order) {
      this.loading = true;
      getByConsignmentOrder(order.id)
        .then((res) => {
          if (res) {
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                o._currentStock = 0;
              });
            }
            this.form = res;
          } else {
            this.resetForm(null, 2, order);
          }
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          // filteredItems
          var saveForm = JSON.parse(JSON.stringify(this.form));
          saveForm.items = this.filteredItems;
          (saveForm.id ? edit : add)(saveForm)
            .then((res) => {
              if (res) this.resetForm(res);
              if (!this.mode) this.$parent.init();
              if (typeof callback === "function") callback();
              else
                this.$notify({
                  title: `保存出库单成功`,
                  type: "success",
                  duration: 2500,
                });
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm("您确定要进行出库吗？", "出库确认", {
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            finish(this.form.id)
              .then((res) => {
                this.$notify({
                  title: "出库确认成功",
                  type: "success",
                  duration: 2500,
                });
                switch (this.mode) {
                  case "purchase":
                    this.$alert(
                      `该采购单已经出库成功，出库单号为：${this.form.formCode}。<br />在“库房管理”中可查看出库单或库存情况。`,
                      "出库提示",
                      {
                        dangerouslyUseHTMLString: true,
                      }
                    );
                    break;
                  default:
                    this.$parent.init();
                    break;
                }
                this.dialog = false;
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    loadStockInfo(g) {
      if (g && g.goodsId && g.warehouseId) {
        // 获取当前行的当前库存
        getGoodsById(g.goodsId, [g.warehouseId])
          .then((res) => {
            if (res && res.length) {
              g._currentStock = res[0].count;
            } else {
              g._currentStock = 0;
            }
          })
          .catch((err) => {
            g._currentStock = 0;
          });
      } else {
        g._currentStock = 0;
      }
    },
    find(source, type) {
      switch (type) {
        case 2:
          this.findByConsignmentOrder(source);
          break;
      }
      this.dialog = true;
    },
    resetForm(form, type, referenceOrder) {
      this.mode = null;
      if (form && form.id) {
        this.loading = true;
        get(form.id)
          .then((res) => {
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                o._currentStock = 0;
                o.warehouseId = res.warehouseId;
                o.warehouseName = res.warehouseName;
                this.loadStockInfo(o);
              });
            }
            this.form = res;
            this.getAuditInfo();
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = Object.assign(
          {
            status: 0,
            formTime: new Date().getTime(),
            manager: this.user.userRealName,
            warehouseId: null,
            warehouseName: "",
            formType: type || 8,
            inoutType: 1,
            info: null,
            installer:null,
            dependFormCode: referenceOrder ? referenceOrder.formCode : "",
            items: [],
          },
          form || {}
        );
        if (referenceOrder) {
          switch (type) {
            case 2:
              this.form.warehouseId = referenceOrder.warehouseId;
              this.loadConsignmentOrder();
              break;
            case 4:
              this.loadReturnOrder();
              break;
            case 6:
              this.form.warehouseId = referenceOrder.outWarehouseId;
              this.loadAllocationOrder();
              break;
          }
        }
      }
      this.dialog = true;
    },
    generateByPurchaseOrder(orderId) {
      if (orderId) {
        this.mode = "purchase";
        this.loading = true;
        getPurchaseOrder(orderId)
          .then((res) => {
            this.form = {
              status: 0,
              formTime: new Date().getTime(),
              manager: "",
              warehouseId: null,
              formType: 0,
              inoutType: 1,
              info: null,
              installer:null,
              dependFormCode: "",
              items: (res.items || []).map((o) => {
                return {
                  brandName: o.goodsBrand,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeries,
                  specs: o.goodsSpec,
                };
              }),
            };
          })
          .finally((_) => {
            this.loading = false;
          });
        this.dialog = true;
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach((o) => {
          let _no = {
            brandName: o.brandName,
            realCount: 1,
            code: o.code,
            erpCode: o.erpCode,
            goodsId: o.id,
            _currentStock: 0,
            goodsName: o.name,
            seriesName: o.seriesName,
            specs: o.specs,
            warehouseId: o.warehouseId,
          };
          this.loadStockInfo(_no);
          ds.push(_no);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.goodsId === row.goodsId;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
  },
};
</script>
