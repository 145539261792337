import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/shop/form',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/shop/form/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'api/shop/form',
    method: 'put',
    data
  })
}

export function submit(id) {
  return request({
    url: "api/shop/form/submit",
    method: 'put',
    data: {
      id: id
    }
  })
}

export function audit(data) {
  return request({
    url: 'api/shop/form/audit',
    method: 'put',
    data
  })
}

export function sendNotice(data) {
  return request({
    url: 'api/shop/form/sendNotice',
    method: 'put',
    data
  })
}

export function cancelAudit(id) {
  return request({
    url: 'api/shop/form/cancelAudit/' + id,
    method: 'put'
  })
}

export function get(id) {
  return request({
    url: "api/shop/form/" + id,
    method: "get"
  })
}

export function getSimple(id) {
  return request({
    url: "api/shop/formView/" + id,
    method: "get"
  })
}

export function getWithPurchasable(id, params) {
  return request({
    url: "api/shop/form/purchase/" + id,
    method: "get",
    params
  })
}

export function send(data) {
  return request({
    url: "api/shop/form/send",
    method: "post",
    data
  })
}

export function close(id) {
  return request({
    url: "api/shop/form/close",
    method: "post",
    data: { id: id }
  })
}

export function sumDetails(params) {
  return request({
    url: "api/shop/orderFormFull/sum",
    method: "get",
    params
  })
}

export function sumDistributorDetails(params) {
  return request({
    url: "api/shop/orderFormFull/distributor/sum",
    method: "get",
    params
  })
}

export function closeItem(itemId) {
  return request({
    url: "api/shop/form/closeRow",
    method: "post",
    data: {
      id: itemId
    }
  })
}

export function changeLimit(orderId) {
  return request({
    url: "api/shop/form/allowChangeShop/" + orderId,
    method: "get"
  })
}

export function checkOrderIsPurchase(orderId) {
  return request({
    url: "api/shop/form/purchase/status?formId=" + orderId,
    method: "get"
  })
}

export function checkContractNo(contractNo) {
  return request({
    url: "api/shop/form/contractNo/check",
    method: "get",
    params: { contractNo }
  })
}

export function changeOrder(data) {
  return request({
    url: "api/shoSaleFormChange",
    method: "post",
    data
  })
}

export function getChangeRecord(saleFormId) {
  return request({
    url: "api/shoSaleFormChange",
    method: "get",
    params: {
      saleFormId
    }
  })
}

export function getChangeDetail(formId) {
  return request({
    url: "api/shoSaleFormChange/" + formId,
    method: "get",
  })
}

export function auditChange(data) {
  return request({
    url: "api/shoSaleFormChange/audit",
    method: "put",
    data
  })
}