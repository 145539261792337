<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="发货通知单" custom-class="custom-dialog-max-width" @closed="form = null">
    <template v-if="form && (form.status === 'input' || form.status === 'reject')">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-suffix=":" label-position="right">
        <div class="h v">
          <!-- <div>
            <form-info-item label="发货单号">
              {{form.formCode}}
              <span v-if="form.erpCode">（ERP编号：{{form.erpCode}}）</span>
            </form-info-item>
            <form-info-item label="收货信息">
              {{form.receiverName}}
              <span v-if="form.phone">（{{form.phone}}）</span>
            </form-info-item>
            <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
          </div>-->
          <div class="h">
            <!-- <el-form-item label="发货库房" prop="warehouseId">
              <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName" auto-select-single-option
                displayField="name" url="api/warehouse" filterable placeholder="请选择库房" @change="handleWarehouseChange"
                style="width: 180px;" />
            </el-form-item>-->
            <el-form-item label="单据日期" prop="formTime">
              <el-date-picker v-model="form.formTime" :clearable="false" value-format="timestamp" style="width: 180px;" />
            </el-form-item>
            <el-form-item key="dfcv" label="零售单号" v-if="form.id">
              <el-input readonly :value="form.dependFormCode" style="width: 180px;" />
            </el-form-item>
            <el-form-item key="dfce" prop="dependFormCode" label="零售单号" v-else>
              <!-- status:'sendPart' -->
              <datagrid-picker reference="选择零售单" reference-type="info" url="api/shop/form" :params="{ statusList: ['pass', 'sendPart', 'closedPart'], sort: 'formCode,desc' }" :query-define="formCodeDefine" :popper-width="800" :show-index="false" @change="handleFormCodeAdd">
                <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                  <el-input :maxlength="20" v-model="formCodeDefine.keywords" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入销售单号/合同号/电话号码搜索" style="width: 250px;">
                    <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                  </el-input>
                </div>
                <el-table-column prop="formCode" label="销售单号" width="120" fixed />
                <el-table-column label="销售类型" width="140" :formatter="formatSaleType" v-if="$entType.get() === 'kingkoil'" />
                <el-table-column prop="purchaseTime" label="单据日期" width="90" :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" />
                <el-table-column prop="contractNo" label="商场合同号" width="100" show-overflow-tooltip />
                <el-table-column prop="shopName" label="门店" min-width="240" show-overflow-tooltip />
                <el-table-column prop="customerName" label="客户名称" width="100" />
                <el-table-column prop="customerPhone" label="客户电话" width="100" show-overflow-tooltip />
                <el-table-column prop="customerAddress" label="客户收货地址" min-width="200" show-overflow-tooltip>
                  <template slot-scope="scope">{{ scope.row.provinceName + scope.row.cityName + scope.row.districtName + scope.row.customerAddress }}</template>
                </el-table-column>
                <el-table-column prop="realTotalMoney" label="总金额" width="120" align="right" :formatter="$price" />
                <el-table-column label="已收款" width="120" align="right">
                  <template slot-scope="scope">
                    <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{ $price(scope.row.payAmount) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="待收款" width="120" align="right">
                  <template slot-scope="scope">
                    <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{ $price(scope.row.realTotalMoney - scope.row.payAmount) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="salesmanName" label="销售人员" width="100" show-overflow-tooltip />
              </datagrid-picker>
              <!-- <el-input :disabled="true" v-model.trim="form.dependFormCode" placeholder="请先选择零售单" style="width: 200px;" /> -->
              <el-input :disabled="!!form.id" v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入零售单号" @change="loadSalesOrder" style="width: 200px;">
                <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
              </el-input>
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="customerName" label="收货人">
              <el-input v-model="form.customerName" :maxlength="25" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="customerPhone" label="联系电话">
              <el-input v-model="form.customerPhone" :maxlength="25" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="contractNo" label="合同号">
              <el-input v-model="form.contractNo" :maxlength="25" style="width: 180px;" readonly />
            </el-form-item>
          </div>
          <div class="h s">
            <el-form-item label="收货地址" prop="provinceCode">
              <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
            </el-form-item>
            <el-form-item label-width="10px" prop="cityCode" style="width: 185px;">
              <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
              <el-input disabled placeholder="请先选择省份" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="districtCode" style="width: 185px;">
              <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
              <el-input disabled placeholder="请先选择城市" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="customerAddress" class="flex">
              <el-input v-model="form.customerAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
          </div>
        </div>
        <el-form-item prop="items" label="发货明细">
          <el-table border :data="form.items" highlight-current-row empty-text="没有商品信息" style="margin: 8px 0;">
            <el-table-column type="index" label="序号" fixed width="60" />
            <el-table-column prop="code" label="商品编码" width="120" />
            <el-table-column prop="erpCode" label="ERP编码" width="120" />
            <el-table-column prop="goodsName" label="商品名称" min-width="170" />
            <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            <el-table-column prop="brandName" label="品牌" width="120" />
            <el-table-column prop="warehouseId" label="发货库房" min-width="145">
              <template slot-scope="scope">
                <!-- <span v-if="scope.row.warehouseId && form.id">{{ getWarehouse(scope.row.warehouseId) }}</span> -->
                <!-- <span v-else> -->
                <span>
                  <quick-select v-model="scope.row.warehouseId" auto-select-single-option displayField="name" url="api/warehouse" filterable placeholder="请选择库房" style="width: 100%;" @change="handleChangeWarehouse(scope.row)" />
                </span>
              </template>
            </el-table-column>
            <el-table-column label="当前库房库存" width="95" align="right">
              <template slot-scope="scope">
                <span v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</span>
                <div v-else-if="scope.row._stockInfo.error" class="h c r">
                  <span class="fc-e">{{ scope.row._stockInfo.error }}</span>
                  <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                </div>
                <span v-else>{{ scope.row.warehouseId ? scope.row._stockInfo.total : "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="deliveryUseCount" label="已占用库存数量(不含本单数量)" width="110" align="right">
              <template slot-scope="scope">{{ scope.row.warehouseId ? scope.row._stockInfo.deliveryUseCount : "-" }}</template>
            </el-table-column>
            <el-table-column label="发货数量" align="right" width="115">
              <template slot-scope="scope">
                <el-input-number v-model="scope.row.quantity" :min="0.01" :max="scope.row._maxQuantity" :step="1" :precision="2" size="mini" controls-position="right" style="width: 100%;" v-if="!targetDependFormCode && form.status === 'input'" />
                <span v-else>{{ scope.row.quantity }}</span>
              </template>
            </el-table-column>
            <el-table-column label="发货日期" width="155" align="center">
              <template slot-scope="scope">
                <el-date-picker v-model.trim="scope.row.deliveryDate" :clearable="false" size="mini" type="date" value-format="timestamp" placeholder="发货日期" style="width: 100%;" v-if="!targetDependFormCode && form.status === 'input'" />
                <span v-else>{{ scope.row.deliveryDate ? new Date(scope.row.deliveryDate).format('yyyy/MM/dd') : '' }}</span>
              </template>
              <!-- <template slot-scope="scope"></template> -->
            </el-table-column>
            <el-table-column prop="info" label="备注" width="140">
              <template slot-scope="scope">
                <el-input type="textarea" v-model="scope.row.info" rows="1" />
              </template>
            </el-table-column>
            <el-table-column width="50" fixed="right" align="center" v-if="!targetDependFormCode">
              <div class="row-commands" slot-scope="scope">
                <el-popover :ref="scope.row._id" placement="top" width="180" v-if="form.status === 'input' || form.status==='reject'">
                  <p>确定移除吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="$refs[scope.row._id].doClose()">取消</el-button>
                    <el-button type="primary" size="mini" @click="remove(scope.row)">确定</el-button>
                  </div>
                  <el-button :loading="itemDeleting" slot="reference" class="danger" type="text" size="mini">移除</el-button>
                </el-popover>
              </div>
            </el-table-column>
          </el-table>
          <dot same type="info">{{ stockList[stockNum]}}</dot>
        </el-form-item>
        <!-- <el-divider />         -->
        <el-form-item prop="info" label="备注">
          <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="2" resize="none" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!targetDependFormCode">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
        <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="form.id">保存并提交</el-button>
      </div>
    </template>
    <template v-if="form && form.status !== 'input' && form.status !== 'reject'">
      <el-form ref="form" :model="form" label-width="80px" label-suffix=":" label-position="right">
        <div class="h">
          <form-info-item label="发货单号">
            <div style="width: 180px;">{{ form.formCode }}</div>
          </form-info-item>
          <form-info-item label="单据日期">
            <div style="width: 180px;">{{ new Date(form.formTime).format('yyyy/MM/dd') }}</div>
          </form-info-item>
          <!-- <form-info-item label="发货库房">
            <div style="width: 440px;">{{ form.warehouseName }}</div>
          </form-info-item>-->
          <form-info-item label="修改时间(人)" label-width="90px">
            <div style="width: 230px;">{{ new Date(form.editAt).format('yyyy/MM/dd hh:mm:ss') + ' (' + form.editBy + ')' }}</div>
          </form-info-item>
        </div>
        <div class="h">
          <form-info-item label="收货人">
            <div style="width: 180px;">{{ form.customerName }}</div>
          </form-info-item>
          <form-info-item label="联系电话">
            <div style="width: 180px;">{{ form.customerPhone }}</div>
          </form-info-item>
          <form-info-item label="收货地址">
            <div style="width: 440px;">{{ form.provinceName }}{{ form.cityName }}{{ form.districtName }}{{ form.customerAddress }}</div>
          </form-info-item>
          <form-info-item label="审批时间(人)" label-width="90px" v-if="form.status !== 'submit' && form.auditAt">
            <div style="width: 220px;">{{ new Date(form.auditAt).format('yyyy/MM/dd hh:mm:ss') + ' (' + form.auditBy + ')' }}</div>
          </form-info-item>
        </div>
        <div class="h">
          <form-info-item label="零售单号">
            <div style="width: 180px;">{{ form.dependFormCode }}</div>
          </form-info-item>
          <form-info-item label="合同号">
            <div style="width: 180px;">{{ form.saleForm.contractNo }}</div>
          </form-info-item>
          <form-info-item label="门店">
            <div style="width: 440px;">{{ form.saleForm.shopName }}</div>
          </form-info-item>
        </div>
        <form-info-item label="发货明细"></form-info-item>
        <el-table border :data="form.items" highlight-current-row empty-text="没有商品信息">
          <el-table-column type="index" label="序号" fixed width="45" />
          <el-table-column prop="code" label="商品编码" width="120" />
          <el-table-column prop="erpCode" label="ERP编码" width="120" />
          <el-table-column prop="goodsName" label="商品名称" min-width="130" />
          <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
          <el-table-column prop="brandName" label="品牌" width="110" />
          <el-table-column prop="warehouseId" label="发货库房" width="90" :formatter="r => { return getWarehouse(r.warehouseId) }" />
          <el-table-column label="当前库房库存" width="100" align="right">
            <template slot-scope="scope">
              <span v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</span>
              <div v-else-if="scope.row._stockInfo.error" class="h c r">
                <span class="fc-e">{{ scope.row._stockInfo.error }}</span>
                <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
              </div>
              <span v-else>{{ scope.row.warehouseId ? scope.row._stockInfo.total : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deliveryUseCount" label="已占用库存数量(不含本单数量)" width="110" align="right">
            <template slot-scope="scope">{{ scope.row.warehouseId ? scope.row._stockInfo.deliveryUseCount : "-" }}</template>
          </el-table-column>
          <el-table-column label="发货数量" align="right" width="80" prop="quantity" />
          <el-table-column label="已出库数量" align="right" width="85" prop="outboundQuantity">
            <template slot-scope="scope">{{ scope.row.outboundQuantity || 0 }}</template>
          </el-table-column>
          <el-table-column label="发货日期" width="155" align="center">
            <template slot-scope="scope">
              <el-date-picker v-model.trim="scope.row.deliveryDate" :clearable="false" size="mini" type="date" value-format="timestamp" placeholder="发货日期" style="width: 100%;" v-if="!targetDependFormCode && form.status === 'submit'" />
              <span v-else>{{ scope.row.deliveryDate ? new Date(scope.row.deliveryDate).format('yyyy/MM/dd') : '' }}</span>
            </template>
            <!-- <template slot-scope="scope"></template> -->
          </el-table-column>
          <el-table-column prop="info" label="备注" min-width="100" show-overflow-tooltip fixed="right" />
        </el-table>
        <form-info-item label="备注">{{ form.info }}</form-info-item>
      </el-form>
      <div v-if="auditable">
        <el-divider content-position="left">审批</el-divider>
        <el-form ref="auditForm" :model="auditForm" :rules="auditRules" label-position="top" label-width="80px">
          <div class="h e">
            <el-form-item label="审批结果" prop="pass">
              <el-radio-group v-model="auditForm.pass">
                <el-radio-button :label="true">同意</el-radio-button>
                <el-radio-button :label="false">不同意</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审批意见" prop="auditInfo" class="flex" label-width="100px" style="margin-left: 20px;">
              <el-input v-model="auditForm.auditInfo" :maxlength="200" />
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
              <el-button type="primary" :loading="saving || submiting" @click="doAudit">提交审批</el-button>
            </el-form-item>
            <el-form-item style="margin-left: 10px;">
              <el-button @click="dialog = false">取消</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div v-else>
        <el-divider />
        <el-form label-width="68px" label-suffix=":" label-position="right">
          <div class="h c">
            <form-info-item label="当前状态">
              <dot same :type="status[form.status].type">{{ status[form.status].label }}</dot>
            </form-info-item>
            <form-info-item label="审批意见" label-width="100px" class="flex" v-if="form.status !== 'submit'">{{ form.auditInfo || "无" }}</form-info-item>
            <div class="flex" v-else></div>
            <div style="width: 50px"></div>
            <el-button type="primary" @click="doPrint" v-if="form.status !== 'input'">打印发货通知单</el-button>
          </div>
        </el-form>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  get,
  add,
  edit,
  getSaleItems,
  submit,
  audit,
  deleteItem,
  printOrder,
} from "@/api/deliveryNotice";
import { getGoodsByParams } from "@/api/warehouse";
import request from "@/utils/request";
export default {
  props: {
    targetDependFormCode: String,
  },
  data() {
    return {
      status: {
        input: {
          type: "info",
          label: "编辑中",
        },
        submit: {
          type: "warning",
          label: "待审批",
        },
        confirm: {
          type: "success",
          label: "已确认",
        },
        reject: {
          type: "warning",
          label: "已拒绝",
        },
        close: {
          type: "info",
          label: "已关闭",
        },
        outbound: {
          type: "success",
          label: "已出库",
        },
        partOut: {
          type: "success",
          label: "部分已出库",
        },
      },
      auditable: false,
      auditForm: { pass: true, auditInfo: null },
      referenceLoading: false,
      itemDeleting: false,
      loading: false,
      dialog: false,
      form: null,
      saving: false,
      submiting: false,
      auditRules: {},
      formCodeDefine: {
        keywords: null,
      },
      warehouseList: null,
      stockNum: 0,
      stockList: [
        "不校验",
        "强制校验(当前为强制校验，库存不足时不允许保存)",
        "校验提醒(当前为校验提醒，库存不足时提醒)",
      ],
      rules: {
        formTime: [
          { required: true, message: "请选择单据日期", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择发货库房", trigger: "change" },
        ],
        items: [
          { required: true, message: "发货明细不能为空" },
          { type: "array", min: 1, message: "发货明细不能为空" },
        ],
        dependFormCode: [{ required: true, message: "请输入或选择相应的单号" }],
        customerName: [{ required: true, message: "请输入收货人" }],
        customerPhone: [{ required: true, message: "请输入联系电话" }],
        provinceCode: [
          { required: true, message: "请选择省份", trigger: "change" },
        ],
        cityCode: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
        districtCode: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        customerAddress: [
          { required: true, message: "收货地址为必填项", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    request({
      url: "api/warehouse",
      method: "get",
      params: {
        page: 0,
        size: 1000,
      },
    }).then((res) => {
      this.warehouseList = res.content || [];
    });
  },
  methods: {
    handleChangeWarehouse(rows) {
      if (this.form.items.length > 0) {
        (this.form.items || []).forEach((g) => {
          if (g.goodsId == rows.goodsId) {
            if (rows.goodsId && rows.warehouseId) {
              let params = {
                goodsId: rows.goodsId,
                warehouseIds: rows.warehouseId,
                containDeliveryUse: true,
                deliveryId: this.form.id,
              };
              getGoodsByParams(params)
                .then((res) => {
                  let count = 0;
                  let deliveryUseCount = 0;
                  (res || []).forEach((ro) => {
                    count += ro.count;
                    deliveryUseCount += ro.deliveryUseCount;
                  });
                  g._stockInfo.total = count;
                  g._stockInfo.deliveryUseCount = deliveryUseCount;
                })
                .catch((err) => {
                  g._stockInfo.error = err.message || "获取库存信息失败";
                })
                .finally((_) => {
                  g._stockInfo.loading = false;
                });
            }
          }
        });
      }
    },
    getWarehouse(warehouseId) {
      if (warehouseId) {
        let wName = null;
        (this.warehouseList || []).forEach((o) => {
          if (warehouseId == o.id) {
            wName = o.name;
          }
        });
        return wName;
      }
    },
    doPrint() {
      this.$print("retail-consignment", this.form.id);
      if (!this.form.isPrinted) {
        printOrder(this.form.id).then((_) => {
          this.$parent.init();
        });
      }
    },
    doAudit() {
      let _auditForm = Object.assign({}, this.auditForm);
      _auditForm.id = this.form.id;
      _auditForm.items = this.form.items;
      if (_auditForm.pass) {
        _auditForm.status = "confirm";
      } else {
        _auditForm.status = "reject";
      }
      this.$refs.auditForm &&
        this.$refs.auditForm.validate().then((_) => {
          this.submiting = true;
          audit(_auditForm.id, _auditForm)
            .then((_) => {
              this.$parent.init();
              this.dialog = false;
              this.$notify({
                title: `审批成功`,
                type: "success",
                duration: 2500,
              });
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          let form = JSON.parse(JSON.stringify(this.form));
          this.submiting = true;
          submit(form)
            .then((res) => {
              this.$parent.init();
              this.dialog = false;
              if (res) this.form.id = res.id;
              this.$notify({
                title: `提交成功`,
                type: "success",
                duration: 2500,
              });
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
    },
    doSave() {
      this.$refs.form &&
        this.$refs.form
          .validate()
          .then((_) => {
            let errorList = "";
            (this.form.items || []).forEach((o, inx) => {
              if (!!!o.warehouseId) {
                errorList += `<div class='padding-05-0'>第${
                  inx + 1
                }行中的商品【${o.goodsName}】请选择库房<div/>`;
              }
              if (
                o._stockInfo.total - o._stockInfo.deliveryUseCount <
                o.quantity
              ) {
                errorList += `<div class='padding-05-0'>第${
                  inx + 1
                }行中的商品【${o.goodsName}】可用库存不足<div/>`;
              }
            });
            if (errorList !== "" && this.stockNum == 1) {
              //强制校验-不能保存
              this.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: errorList,
                type: "error",
              });
            } else if (errorList !== "" && this.stockNum == 2) {
              //校验提醒-提醒,选择保存
              this.$confirm(errorList + "是否继续保存?", "提示", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  let form = JSON.parse(JSON.stringify(this.form));
                  this.saving = true;
                  (this.form.id ? edit : add)(form)
                    .then((res) => {
                      this.$parent.init();
                      if (res) this.resetForm(res);
                      this.$notify({
                        title: `保存成功`,
                        type: "success",
                        duration: 2500,
                      });
                    })
                    .finally((_) => {
                      this.saving = false;
                    });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消保存",
                  });
                });
            } else {
              let form = JSON.parse(JSON.stringify(this.form));
              this.saving = true;
              (this.form.id ? edit : add)(form)
                .then((res) => {
                  this.$parent.init();
                  if (res) this.resetForm(res);
                  this.$notify({
                    title: `保存成功`,
                    type: "success",
                    duration: 2500,
                  });
                })
                .finally((_) => {
                  this.saving = false;
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    remove(item) {
      var index = this.form.items.indexOf(item);
      if (this.form.id) {
        this.itemDeleting = true;
        deleteItem(item.id, item)
          .then((_) => {
            this.$notify({
              title: `移除成功`,
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.itemDeleting = false;
            index > -1 && this.form.items.splice(index, 1);
          });
      } else {
        index > -1 && this.form.items.splice(index, 1);
      }
    },

    handleWarehouseChange() {
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          this.loadStockInfo(o);
        });
      }
    },
    //
    handleFormCodeAdd(v, o, res) {
      if (res) {
        this.form.dependFormCode = res.formCode;
        this.form.contractNo = res.contractNo;
        this.loadSalesOrder(res.formCode);
      }
    },
    loadSalesOrder(fc) {
      if (!fc) return;
      this.referenceLoading = true;
      getSaleItems(fc)
        .then((res) => {
          if (
            res.saleForm.fullPaymentShip &&
            res.saleForm.realTotalMoney > res.saleForm.payAmount
          ) {
            //提示此销售单未付完全款，是否继续
            this.$message(
              `当前订单要求【全款发货】,当前还有尾款【${
                (res.saleForm.realTotalMoney - res.saleForm.payAmount) / 100
              }】,不能发货`
            );
            return;
          } else {
            res.items.forEach((o) => {
              o._id = this.$uuid();
              o._maxQuantity = (o.quantity || 0) + (o.maxQuantity || 0);
              o._stockInfo = {
                loading: false,
                error: false,
                total: 0,
                deliveryUseCount: 0,
              };
            });

            res.formTime = this.form.formTime;
            res.status = this.form.status;
            this.form = res;
            this.loadStockInfo();
          }
        })
        .finally((_) => {
          this.referenceLoading = false;
        });
    },
    loadSettingStockNum() {
      request({
        url: "api/distributor/deliveryStockCheck",
        method: "get",
      }).then((res) => {
        this.stockNum = res || 0; //0 不校验  1 强制校验 2 校验提醒
      });
    },
    resetForm(form, auditable) {
      this.loadSettingStockNum();
      this.auditable = auditable;
      this.dialog = true;
      this.auditForm = { pass: true, auditInfo: null };
      if (form && form.id) {
        get(form.id).then((res) => {
          if (res.items && res.items.length) {
            res.items.forEach((o) => {
              o._maxQuantity = (o.quantity || 0) + (o.maxQuantity || 0);
              o._stockInfo = {
                loading: false,
                error: false,
                total: 0,
              };
            });
          }
          this.form = res;
          this.loadStockInfo();
        });
        // this.dialog = true;
      } else {
        this.form = { status: "input", formTime: new Date().getTime() };
      }
    },
    loadStockInfo() {
      if (this.form.items) {
        (this.form.items || []).forEach((g) => {
          g._stockInfo.loading = true;
          g._stockInfo.error = false;
          // 0 不校验  1 强制校验 2 校验提醒
          if (g.goodsId && g.warehouseId) {
            let params = {
              goodsId: g.goodsId,
              warehouseIds: g.warehouseId,
            };
            if (this.stockNum == 1 || this.stockNum == 2) {
              params.containDeliveryUse = true;
              params.deliveryId = this.form.id || null;
            } else {
              delete params.containDeliveryUse;
              delete params.deliveryId;
            }
            getGoodsByParams(params)
              .then((res) => {
                let count = 0;
                let deliveryUseCount = 0;
                (res || []).forEach((ro) => {
                  count += ro.count;
                  deliveryUseCount += ro.deliveryUseCount;
                });
                g._stockInfo.total = count;
                g._stockInfo.deliveryUseCount = deliveryUseCount;
              })
              .catch((err) => {
                g._stockInfo.error = err.message || "获取库存信息失败";
              })
              .finally((_) => {
                g._stockInfo.loading = false;
              });
          } else {
            g._stockInfo.loading = false;
            g._stockInfo.error = false;
            g._stockInfo.total = 0;
          }
        });
      } else {
        (this.form.items || []).forEach((g) => {
          g._stockInfo.loading = false;
          g._stockInfo.error = false;
          g._stockInfo.total = 0;
        });
      }
    },
    formatSaleType(row) {
      let v = "直营店销售",
        el = this.$refs.salesType;
      if (el && row.salesTypeId) {
        v = el.getDisplayText(row.salesTypeId);
      }
      return v;
    },
  },
};
</script>
