<template>
  <div class="app-container v">
    <div class="head-container">
      <el-input v-model.trim="query.salesmanName" clearable placeholder="销售人员搜索" class="filter-item" style="width: 130px" @keyup.enter.native="toQuery" />
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="reQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.salesmanId" class="filter-item" v-if="query.shopId" filterable style="width: 130px;" :url="`api/users?shopId=${query.shopId}`" value-field="id" display-field="userRealName" placeholder="选择销售人员" clearable/>
      <el-input class="filter-item" disabled placeholder="请先选择销售门店" v-else style="width: 130px;" />
      <el-select v-model="dateType" placeholder="日期类型" class="filter-item" style="width: 100px" @change="reQuery">
        <el-option v-for="(v,k) in dateTypes" :key="k" :label="v" :value="k" />
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 210px"/>
      <el-date-picker v-model="query.deliveryDate" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="交货日期" end-placeholder :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 210px"/>

      <el-select v-if="!query.isPrepare" v-model="query.statusList" filterable clearable placeholder="订单状态" class="filter-item" style="width: 120px" @change="reQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="query.changeStatus" filterable clearable placeholder="变更状态" class="filter-item" style="width: 120px" @change="reQuery">
        <el-option v-for="v in changeStatus" :key="v.id" :label="v.label" :value="v.id" />
      </el-select>
      <el-select v-model="query.payStatus" filterable clearable placeholder="收款状态" class="filter-item" style="width: 100px" @change="reQuery">
        <el-option v-for="(v,k) in payStatus" :key="k" :label="v" :value="k" />
      </el-select>

      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="reQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <el-select v-model="query.sendNotice" filterable clearable placeholder="上报状态" class="filter-item" style="width: 120px;" @change="reQuery">
        <el-option label="已上报" :value="true" />
        <el-option label="未上报" :value="false" />
      </el-select>
      <el-select v-model="query.isPurchased" filterable clearable placeholder="采购状态" class="filter-item" style="width: 120px;" @change="reQuery">
        <el-option label="已采购" :value="true" />
        <el-option label="未采购" :value="false" />
      </el-select>
      <el-input v-model="query.purchaseFormCode" class="filter-item" placeholder="采购单号" @keypress.enter.native="reQuery" style="width: 150px;" />

      <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码搜索" @keypress.enter.native="reQuery" style="width: 220px;" />
      <el-input v-model="query.goodsName" class="filter-item" :maxlength="20" placeholder="商品ERP编码或名称" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.goodsSeries" class="filter-item" :maxlength="20" placeholder="商品系列" @keypress.enter.native="reQuery" style="width: 120px;" />
      <el-input v-model="query.goodsSpec" class="filter-item" :maxlength="20" placeholder="商品规格" @keypress.enter.native="reQuery" style="width: 150px;" />
      <el-input v-model="query.info" class="filter-item" :maxlength="50" placeholder="产品备注" @keypress.enter.native="reQuery" style="width: 120px;" />
      <el-input v-model="query.financePromotion" class="filter-item" :maxlength="50" placeholder="产品活动" @keypress.enter.native="reQuery" style="width: 120px;" v-if="$erp.enable()" />
      <quick-select v-model="query.warehouseId" filterable url="api/warehouse" placeholder="请选择发货库房" clearable style="width: 140px;" @change="reQuery" />
      <!-- <el-checkbox border class="filter-item" v-model="query.isPrepare" @change="query.status = null; reQuery();">预售订单</el-checkbox> -->
      <el-input v-model="query.createBy" class="filter-item" :maxlength="20" placeholder="创建人" @keypress.enter.native="reQuery" style="width: 120px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">原单金额</div>
          <counter class="num" :end-val="count.amount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售金额</div>
          <counter class="num" :end-val="count.realAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">收款金额</div>
          <counter class="num" :end-val="count.payAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">送货数量</div>
          <counter class="num" :end-val="count.sendCount" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">送货金额</div>
          <counter class="num" :end-val="count.sendAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">订单数量</div>
          <counter class="num" :end-val="count.totalForm" :decimals="0" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售数量</div>
          <counter class="num" :end-val="count.totalQuantity" :decimals="2" :duration="1000" />
        </div>
      </div>
    </el-card>

    <el-table v-loading="loading" row-key="id" :data="data" border :default-sort="{prop: 'purchaseTime', order: 'descending'}" @sort-change="handleSort" height="200px" style="margin-top: 10px;">
      <el-table-column prop="formCode" label="销售单号" width="120" fixed sortable="custom" />
      <el-table-column prop="purchaseTime" label="单据日期" width="100" :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" sortable="custom" />
      <el-table-column prop="auditTime" label="审核日期" width="100" :formatter="r => { return r.auditTime ? new Date(r.auditTime).format('yyyy/MM/dd'):''; }" sortable="custom" />
      <el-table-column prop="shopName" label="门店" min-width="200" show-overflow-tooltip sortable="custom" />
      <el-table-column prop="contractNo" label="商场合同号" width="120" show-overflow-tooltip sortable="custom" />
      <el-table-column label="商品编码" prop="code" width="160" sortable="custom" />
      <el-table-column label="ERP编码" prop="erpCode" width="160" sortable="custom" />
      <el-table-column label="商品" prop="goodsName" min-width="200" sortable="custom" />
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" sortable="custom" show-overflow-tooltip/>
      <el-table-column prop="brandName" label="品牌" width="100" sortable="custom" />
      <el-table-column prop="goodsCategoryName" label="分类" width="100" sortable="custom" />
      <el-table-column prop="seriesName" label="系列" width="100" sortable="custom" />
      <el-table-column label="数量" prop="quantity" sortable="custom" width="80" align="right" />
      <el-table-column label="统一零售价" prop="price" width="120" sortable="custom" align="right">
        <template slot-scope="scope">{{$price(scope.row.price)}}</template>
      </el-table-column>
      <el-table-column label="折扣率" width="90" align="right" :formatter="calcProductDiscountPercent" />
      <el-table-column label="成交价" prop="price" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price(scope.row.realPrice)}}</template>
      </el-table-column>
      <el-table-column label="金额" prop="amount" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price((scope.row.quantity-scope.row.cancelCount)*scope.row.realPrice)}}</template>
      </el-table-column>
      <el-table-column label="成本单价" prop="price" sortable="custom" width="100" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.purchasePrice)}}</template>
      </el-table-column>
      <el-table-column label="成本小计" prop="price" sortable="custom" width="100" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price((scope.row.quantity-scope.row.cancelCount)*scope.row.purchasePrice)}}</template>
      </el-table-column>
      <el-table-column label="已发货数量" width="120" prop="sendCount" sortable="custom" align="right" />
      <el-table-column label="已取消数量" width="120" prop="cancelCount" sortable="custom" align="right" />
      <el-table-column label="现场提货" width="120" prop="sendType" sortable="custom" align="center">
        <template slot-scope="scope">{{scope.row.sendType?'否':'是'}}</template>
      </el-table-column>
      <el-table-column label="交货日期" width="120" prop="deliveryDate" sortable="custom" :formatter="v=>{return v.deliveryDate?new Date(v.deliveryDate).format('yyyy/MM/dd'):''}" />
      <el-table-column label="备注" width="200" prop="info" show-overflow-tooltip />
      <el-table-column label="采购状态" width="100" align="center" prop="isPurchased" sortable="custom">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isPurchased" active-color="#13ce66" @change="doChangePurchaseStatus(scope.row)" :disabled="scope.row.isPurchased && scope.row.purchaseFormCode" />
        </template>
      </el-table-column>
      <el-table-column label="采购单号" width="120" prop="purchaseFormCode" show-overflow-tooltip />
      <el-table-column label="参与活动" width="120" prop="shoPromotionName" show-overflow-tooltip />
      <el-table-column label="财务活动" width="120" prop="financePromotion" show-overflow-tooltip />
      <el-table-column label="发货库房" width="120" prop="warehouseName" show-overflow-tooltip />
      <el-table-column prop="salesmanName" label="销售人员" width="120" show-overflow-tooltip sortable="custom" />
      <el-table-column prop="customerName" label="客户名称" width="100" sortable="custom" />
      <el-table-column prop="customerPhone" label="客户电话" width="110" show-overflow-tooltip />
      <el-table-column prop="customerAddress" label="客户收货地址" min-width="120" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">{{scope.row.provinceName+scope.row.cityName+scope.row.districtName+scope.row.customerAddress}}</template>
      </el-table-column>
      <el-table-column prop="realTotalMoney" label="订单金额" width="120" align="right" :formatter="$price" sortable="custom" />
      <el-table-column prop="payAmount" label="订单已收款" width="120" align="right" sortable="custom">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单待收款" width="120" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{$price(scope.row.realTotalMoney-scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单折扣率" width="90" align="right" :formatter="calcDiscountPercent" />
      <!-- <el-table-column prop="deliveryDate" label="交货日期" width="90" :formatter="r => { return r.deliveryDate?new Date(r.deliveryDate).format('yyyy/MM/dd'):''; }" /> -->
      <el-table-column prop="remarks" label="订单摘要" min-width="120" show-overflow-tooltip />
      <el-table-column prop="source" label="订单来源" width="120" show-overflow-tooltip />

      <el-table-column prop="status" label="关闭状态" width="100" align="center" sortable="custom">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.cancelCount >0&&scope.row.quantity==scope.row.cancelCount">已关闭</el-tag>
          <el-tag type="info" v-else-if="scope.row.cancelCount >0&&scope.row.quantity>scope.row.cancelCount">部分关闭</el-tag>
          <el-tag type="info" v-else-if="scope.row.cancelCount ==0">未关闭</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="订单状态" width="100" align="center" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="已上报" width="90" align="center" prop="sendNotice" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.sendNotice"></i>
        </template>
      </el-table-column>
      <el-table-column prop="deviceInfo" label="操作来源" width="90" show-overflow-tooltip />
      <el-table-column prop="createBy" label="创建人" width="90" show-overflow-tooltip />
      <el-table-column width="110px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
          <el-button size="mini" type="text" v-if="checkPermission(['FINANCE_PROMOTION'])" @click="setPromotion(scope.row)">财务活动</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" />

    <el-dialog title="设置财务活动" :visible.sync="promotion.show" append-to-body :close-on-click-modal="false" width="560px" @closed="promotion.form = null">
      <el-form :model="promotion.form" label-position="top" v-if="promotion.order && promotion.form">
        <form-info-item label="销售单号：" label-position="left">{{promotion.order.formCode}}</form-info-item>
        <form-info-item label="商品信息：" label-position="left">
          {{promotion.order.goodsName}}
          <br />
          <span class="fs-mini fc-g">{{$goodsSpecConvert(promotion.order.specs)}}</span>
        </form-info-item>
        <el-form-item label="财务活动：">
          <el-input v-model="promotion.form.financePromotion" :maxlength="50" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="promotion.show = false">取消</el-button>
        <el-button :loading="promotion.saving" type="primary" @click="doSavePromotion">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { sumDetails } from "@/api/shopOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Counter from "vue-count-to";
import request from "@/utils/request";

export default {
  components: { eForm, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      dateType: "sale",
      dateTypes: {
        sale: "销售日期",
        audit: "审核日期",
      },
      //时间查询
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款",
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        // unsend: { key: "unsend", label: "待发货", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closedPart: { key: "closedPart", label: "部分关闭", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
      },
      sort: "purchaseTime,desc",
      count: null,
      query: {
        salesmanName: null,
        shopId: null,
        dateRange: null,
        status: null,
        payStatus: null,
        keywords: null,
        goodsName: null,
        salesmanId: null,
      },
      promotion: {
        order: null,
        form: null,
        show: false,
        saving: false,
      },
      changeStatus: [
        { id: -1, label: "未变更", type: "gray" },
        { id: 0, label: "待审核", type: "gray" },
        { id: 1, label: "审核通过", type: "success" },
        { id: 2, label: "审核未通过", type: "danger" },
      ],
    };
  },
  created() {
    this.reQuery();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/orderFormFull";
      let query = JSON.parse(JSON.stringify(this.query));
      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "sale"
      ) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }

      if (
        query.dateRange &&
        query.dateRange.length === 2 &&
        this.dateType === "audit"
      ) {
        query.auditBegTime = query.dateRange[0];
        query.auditEndTime = query.dateRange[1];
      }
      delete query.dateRange;

      if (query.deliveryDate && query.deliveryDate.length === 2) {
        query.deliveryDateMin = query.deliveryDate[0];
        query.deliveryDateMax = query.deliveryDate[1];
      }
      delete query.deliveryDate;

      this.params = Object.assign(
        { page: this.page, size: this.size, sort: this.sort },
        query
      );
      if (this.params.isPrepare) {
        this.params.statusList = [];
      } else {
        // this.params.statusList = [
        //   "pass",
        //   "unsend",
        //   "sendPart",
        //   "sended",
        //   "closed"
        // ];
      }
      return true;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    loadSums() {
      sumDetails(this.params).then((res) => {
        this.count = res;
      });
    },
    reQuery() {
      this.toQuery();
      this.loadSums();
    },
    calcProductDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcDiscountPercent(row) {
      let dm = row.totalMoney - row.realTotalMoney;
      if (dm <= 0) return "100%";
      else
        return ((row.realTotalMoney / row.totalMoney) * 100).toFixed(2) + "%";
    },
    view(data) {
      let d = JSON.parse(JSON.stringify(data));
      d.id = d.formId;
      this.$refs.form && this.$refs.form.resetForm(d);
    },
    setPromotion(row) {
      this.promotion.order = row;
      this.promotion.form = {
        id: row.id,
        financePromotion: row.financePromotion,
      };
      this.promotion.show = true;
    },
    doSavePromotion() {
      this.promotion.saving = false;
      request({
        url: "api/shop/orderFormItem/financePromotion",
        method: "put",
        data: this.promotion.form,
      }).then((res) => {
        this.$message.success("保存财务活动设置成功");
        this.init();
        this.promotion.show = false;
      });
    },
    doChangePurchaseStatus(data) {
      request({
        url: "api/shop/form/item/purchaseStatus/"+data.id+"?isPurchased="+data.isPurchased,
        method: "put",
      }).then(_ => {
        this.$message.success("操作成功");
      });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      if (
        // !this.$erp.enable() &&
        this.checkPermission(["PURCHASE_ALL", "PURCHASE_TOTALMONEY"])
      ) {
        this.params.showMoney = true;
      } else {
        this.params.showMoney = false;
      }
      this.downloadLoading = true;
      download("api/shop/orderFormFull/download", this.params)
        .then((result) => {
          downloadFile(result, "零售销售明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>