import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/shop/deliveryNotice',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/shop/deliveryNotice',
    method: 'put',
    data
  })
}

export function submit(data) {
  return request({
    url: 'api/shop/deliveryNotice/submit',
    method: 'put',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/shop/deliveryNotice/' + id,
    method: 'delete'
  })
}

export function deleteItem(id,data) {
  return request({
    url: 'api/shop/deliveryNotice/item/' + id,
    method: 'delete',
    data
  })
}

export function get(id) {
  return request({
    url: 'api/shop/deliveryNotice/' + id,
    method: 'get'
  })
}

export function getSaleItems(formCode) {
  return request({
    url: 'api/shop/salesItem/' + formCode,
    method: 'get'
  })
}

export function audit(id,data){
  return request({
    url: 'api/shop/deliveryNotice/audit/'+id,
    method: 'put',
    data
  })
}

export function closeOrder(id){
  return request({
    url: 'api/shop/deliveryNotice/close/'+id,
    method: 'put'
  })
}

export function rollbackOrder(id){
  return request({
    url: 'api/shop/deliveryNotice/rollback/'+id,
    method: 'put'
  })
}

export function printOrder(id){
  return request({
    url: 'api/shop/deliveryNotice/print/'+id,
    method:'put'
  })
}