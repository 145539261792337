<template>
  <el-dialog :visible.sync="dialog" title="收/退款明细" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1080px">
    <pays-info :editable="editable" :target="target" :temps="temps" @pay-completed="handlePayCompleted" />
  </el-dialog>
</template>

<script>
import paysInfo from "./pays-info";

export default {
  components: { paysInfo },
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      target: null,
      temps: []
    };
  },
  methods: {
    handleClosed() {
      this.target = null;
      this.temps = [];
    },
    handlePayCompleted() {
      this.$emit("pay-completed");
    },
    show(target, temps = []) {
      this.target = target;
      this.temps = temps;
      // if (target && !temps) {
      //   this.init();
      // } else {
      //   this.data = temps || [];
      // }
      this.dialog = true;
    }
  }
};
</script>