<template>
  <el-dialog :visible.sync="dialog" title="选择零售单" append-to-body :close-on-click-modal="false" @closed="cancel" width="1180px">
    <div class="head-container">
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 320px;" />
      <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="输入零售单号/合同单号搜索" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
    </div>
    <el-table ref="list" v-loading="loading" :data="data" highlight-current-row @current-change="handleOrderChange" style="width: 100%;">
      <el-table-column prop="formCode" label="销售单号" width="120" fixed />
      <el-table-column prop="contractNo" label="合同号" width="130" show-overflow-tooltip/>
      <el-table-column prop="shopName" label="门店" min-width="120" show-overflow-tooltip />
      <el-table-column prop="customerName" label="客户姓名" min-width="120" show-overflow-tooltip />
      <el-table-column prop="totalMoney" label="总金额" width="90" align="center" :formatter="$price" />
      <el-table-column label="已付款" width="120" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount >= scope.row.totalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="90" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseTime" label="下单时间" width="150" :formatter="r => { return new Date(r.purchaseTime).format(); }" />
      <el-table-column prop="info" label="备注" min-width="120" show-overflow-tooltip />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <div slot="footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button type="primary" :disabled="!current" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  data() {
    return {
      dialog: false,
      loading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        pass: { key: "pass", label: "审批通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closed: { key: "closed", label: "已关闭", type: "success" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        closedPart:{key: "closedPart", label: "部分已关闭", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "success" }
      },
      current: null,
      query:{
        shopId:null,
        dateRange:null,
        formCode:null,
      }
    };
  },
  methods: {
    cancel() {
      this.$refs.list && this.$refs.list.setCurrentRow();
    },
    beforeInit() {
      this.url = "api/shop/return/form";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { statusList: ["sended", "closed","sendPart","closedPart"], sort: "createAt,desc" },
        query
      );
      return true;
    },
    show() {
      this.current = null;
      this.toQuery();
      this.dialog = true;
    },
    handleOrderChange(data) {
      this.current = data;
    },
    handleSubmit() {
      if (this.current) {
        this.$emit("select", this.current);
        this.dialog = false;
      }
    }
  }
};
</script>