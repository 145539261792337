<template>
  <div class="app-container">
    <div class="head-container">
      <el-input v-model="query.keyword" :maxlength="20" placeholder="输入姓名、联系电话搜索" class="filter-item" style="width: 240px;" @keyup.enter.native="toQuery"/>
      <el-select v-model="query.isMain" filterable clearable placeholder="全部客户" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="长期客户" :value="true" />
        <!-- <el-option label="一次性客户" :value="false" /> -->
      </el-select>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button v-permission="['CUSTOMER_ALL','CUSTOMER_CREATE']" class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button v-permission="['CUSTOMER_ALL','CUSTOMER_EXPORT']" class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <!-- <el-table-column type="index" /> -->
      <el-table-column prop="name" label="姓名" width="150" />
      <el-table-column prop="phone" label="手机号码" width="180" />
      <el-table-column label="联系地址" min-width="360">
        <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}</template>
      </el-table-column>
      <el-table-column label="注册录入时间" width="180">
        <template slot-scope="scope">{{new Date(scope.row.createAt).format()}}</template>
      </el-table-column>
      <el-table-column label="长期客户">
        <template slot-scope="scope">{{scope.row.isMain?"是":"否"}}</template>
      </el-table-column>
      <el-table-column width="100" align="center">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)" v-permission="['CUSTOMER_ALL','CUSTOMER_EDIT']">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-permission="['CUSTOMER_ALL','CUSTOMER_DELETE']">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { del } from "@/api/customer";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      loading: false,
      query: {
        keyword: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/customer/my";
      const sort = "createAt,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      if (this.query.keyword) {
        this.params.keyword = this.query.keyword;
      }
      this.params.isMain = this.query.isMain;
      return true;
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/customer/download", this.params)
        .then((result) => {
          downloadFile(result, "消费者", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>