<template>
  <div class="v" :class="targetDependFormCode ? '' : 'app-container'">
    <div class="head-container" v-if="!targetDependFormCode">
      <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <quick-select v-model="query.status" :options="status" display-field="label" placeholder="退货单状态" clearable class="filter-item" style="width: 120px;" @change="toQuery" />
      <el-input v-model="query.buyerName" placeholder="请输入客户姓名" class="filter-item" style="width: 180px;" />
      <el-input v-model="query.buyerPhone" placeholder="请输入客户联系电话" class="filter-item" style="width: 180px;" />
      <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="退货单号/合同号搜索" @keypress.enter.native="toQuery" style="width: 200px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <div style="display: inline-block;margin: 0px 2px;" v-permission="['RETAILRETURN_ALL','RETAILRETURN_CREATE']">
        <!-- <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增退货</el-button> -->
        <el-dropdown szie="mini" type="primary" @click="add" @command="handleAddCmd" class="filter-item">
          <!-- <i class="el-icon-plus"></i>  -->
          <el-button type="primary">新增退货</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="order">销售单退货</el-dropdown-item>
            <el-dropdown-item command="no-source">无来源退货</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      <!-- <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading"  @click="toDownload">导出</el-button> -->
    </div>
    <el-table v-loading="loading" :data="data" row-key="id" border height="200px">
      <template v-if="targetDependFormCode">
        <el-table-column prop="formCode" label="退货单号" width="125" />
      </template>
      <template v-else>
        <el-table-column prop="formCode" label="退货单号" width="125" fixed />
        <el-table-column prop="contractNo" label="商场合同号" width="150" fixed />
        <el-table-column prop="orderFormCode" label="关联订单号" width="120" fixed />
        <el-table-column prop="shopName" label="门店" min-width="240" show-overflow-tooltip />
        <el-table-column prop="buyerName" label="客户" width="120" />
        <el-table-column prop="buyerPhone" label="客户电话" width="100" />
      </template>
      <el-table-column prop="createAt" label="创建时间" width="135" :formatter="r => { return new Date(r.createAt).format(); }" />
      <el-table-column prop="totalMoney" label="应退金额" width="120" align="right" :formatter="$price" />
      <el-table-column prop="realBackMoney" label="实退金额" width="120" align="right" :formatter="$price" />
      <el-table-column label="发货地址" min-width="240" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.sendProvince}}{{scope.row.sendCity}}{{scope.row.sendDistrict}}{{scope.row.sendAddress}}</template>
      </el-table-column>
      <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
      <!-- <el-table-column label="已发货" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isSend"></i>
        </template>
      </el-table-column>-->
      <el-table-column label="已收货" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isReceive"></i>
        </template>
      </el-table-column>
      <el-table-column label="已退款" width="60" align="center">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isRefund"></i>
        </template>
      </el-table-column>
      <el-table-column label="退款时间" width="140" :formatter="v=>{return v.backMoneyTime?new Date(v.backMoneyTime).format():''}" />
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50px" v-if="targetDependFormCode">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>
      </el-table-column>
      <el-table-column width="150px" fixed="right" v-else>
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)" v-if="scope.row.status !== 0">查看</el-button>
          <el-button size="mini" type="text" @click="audit(scope.row)" v-if="scope.row.status === 1 " v-permission="['RETAILRETURN_ALL','RETAILRETURN_AUDIT']">审批</el-button>
          <template v-if="scope.row.status === 2">
            <el-button size="mini" type="text" @click="edit(scope.row, 'inbound')" v-if="!scope.row.isReceive" v-permission="['RETAILRETURN_ALL','RETAILRETURN_INBOUND']">退货入库</el-button>
            <el-button size="mini" type="text" @click="edit(scope.row, 'refund')" v-if="!scope.row.isRefund && scope.row.isReceive" v-permission="['RETAILRETURN_ALL','RETAILRETURN_REFUND']">退款</el-button>
          </template>
          <el-button size="mini" type="text" @click="print(scope.row)" v-if="scope.row.status > 3 || scope.row.status === 2">打印</el-button>
          <template v-if="scope.row.status === 0">
            <el-button size="mini" type="text" @click="edit(scope.row)" v-permission="['RETAILRETURN_ALL','RETAILRETURN_EDIT']">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180" v-permission="['RETAILRETURN_ALL','RETAILRETURN_DELETE']">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" :target-depend-form-code="targetDependFormCode" />
    <reference-order ref="referenceOrder" @select="addByOrder" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { del } from "@/api/shopOrderBackForm";
import referenceOrder from "./reference-order";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm, referenceOrder },
  mixins: [initData],
  props: {
    targetDependFormCode: String,
  },
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: [
        {
          id: 0,
          type: "info",
          label: "编辑中",
        },
        {
          id: 1,
          type: "warning",
          label: "待审批",
        },
        {
          id: 2,
          type: "success",
          label: "审批通过",
        },
        {
          id: 3,
          type: "danger",
          label: "审批拒绝",
        },
        {
          id: 4,
          type: "success",
          label: "已发送",
        },
        {
          id: 5,
          type: "success",
          label: "已回库",
        },
        {
          id: 6,
          type: "danger",
          label: "已完成",
        },
      ],
      query: {
        buyerName: null,
        buyerPhone: null,
        dateRange: null,
        keywords: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shoBackForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;

      if (this.targetDependFormCode) {
        query.orderFormCode = this.targetDependFormCode;
      }
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "createAt,desc",
          backType: 1,
        },
        query
      );
      return true;
    },

    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    handleAddCmd(ac) {
      if (ac === "order") this.add();
      else if (ac === "no-source")
        this.$refs.form && this.$refs.form.resetForm();
    },
    add() {
      this.$refs.referenceOrder && this.$refs.referenceOrder.show();
    },
    addByOrder(order) {
      this.$refs.form && this.$refs.form.resetForm(null, order);
    },
    audit(data) {
      this.$refs.form && this.$refs.form.resetForm(data, null, true);
    },
    edit(data, action = null) {
      this.$refs.form && this.$refs.form.resetForm(data, null, false, action);
    },
    print(data) {
      this.$print("retail-return", data.id);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shoBackForm/download", this.params)
        .then((result) => {
          downloadFile(result, "零售退货", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>