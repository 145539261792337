<template>
  <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" @closed="handleClosed" custom-class="custom-dialog-max-width">
    <template v-if="form">
      <div key="editor" v-if="editable">
        <div class="bc-l" style="margin: 0 -20px; padding: 1px" v-if="form.isAutoSubmit && auditStore && auditStore.length">
          <ul class="lh-150">
            <span class="bold fc-e">自动提交订单失败，请编辑订单后手动提交。失败原因：</span>
            <template v-for="a in auditStore">
              <li :key="a.id">
                <span class="fc-g">审核时间：</span>
                {{new Date(a.auditTime).format()}}
                <span class="fc-g">&emsp;审核备注：</span>
                {{a.remark}}
              </li>
            </template>
          </ul>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <el-form-item label="审批意见" v-if="form.auditRemark">
            <div :class="status[form.status].type" style="padding: 6px 0; line-height: 1.5 !important;">{{ form.auditRemark }}</div>
          </el-form-item>

          <template v-if="form.id">
            <div class="h">
              <el-form-item label="销售单号">
                <div style="width: 180px;">{{ form.formCode }}</div>
              </el-form-item>
              <el-form-item label="创建时间" label-width="100px">{{ new Date(form.createAt).format() }}</el-form-item>
            </div>
          </template>

          <div class="h">
            <el-form-item label="预售订单">
              <div style="width: 180px;">
                <el-checkbox v-model="form.isPrepare" />
              </div>
            </el-form-item>
            <el-form-item prop="salesTypeId" label="销售类型" label-width="100px" v-if="$erp.enable()">
              <quick-select v-model="form.salesTypeId" filterable style="width: 180px;" url="api/salesType?isDeleted=0" value-field="id" display-field="name" placeholder="选择销售类型" />
            </el-form-item>
          </div>

          <div class="h">
            <el-form-item prop="customerName" label="客户姓名">
              <div style="width: 264px;" class="h c">
                <el-input v-model.trim="form.customerName" :maxlength="20" placeholder="客户姓名" style="margin-right: 5px;" class="flex"></el-input>
                <datagrid-picker reference="选择客户" reference-icon reference-type="primary" dialog dialog-title="客户列表" url="api/customer/my" :params="{isMain: true}" :query-define="customerQueryDefine" @change="handleCustomerSelect">
                  <div class="h c" slot="banner" slot-scope="scope" style="margin-bottom: 10px;">
                    <el-input v-model="customerQueryDefine.keyword" :maxlength="20" placeholder="输入姓名、联系电话搜索" class="filter-item" style="width: 240px;" @change="scope.query" />
                  </div>
                  <el-table-column prop="name" label="姓名" width="150" />
                  <el-table-column prop="phone" label="手机号码" width="180" />
                  <el-table-column label="联系地址" min-width="360">
                    <template slot-scope="scope">{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}</template>
                  </el-table-column>
                </datagrid-picker>
              </div>
            </el-form-item>
            <el-form-item prop="customerPhone" label="手机号码" label-width="100px">
              <el-input v-model.trim="form.customerPhone" :maxlength="11" style="width: 180px;" placeholder="输入手机号码" />
            </el-form-item>
            <el-form-item prop="purchaseTime" label="单据日期" label-width="100px">
              <el-date-picker v-model.trim="form.purchaseTime" type="date" :picker-options="purchaseTimeOptions" format="yyyy-MM-dd" value-format="timestamp" style="width: 180px;" placeholder="实际下单据日期" />
            </el-form-item>
            <!-- <el-form-item prop="deliveryDate" label="送货日期" label-width="100px">
              <el-date-picker v-model.trim="form.deliveryDate" type="date" style="width: 180px;" value-format="timestamp" placeholder="客户期望送货日期"></el-date-picker>
            </el-form-item>-->
            <el-form-item prop="fullPaymentShip" label="全款发货" label-width="100px">
              <!-- <el-checkbox v-model="form.fullPaymentShip"></el-checkbox> -->
              <el-switch v-model="form.fullPaymentShip" :disabled="!checkPermission(['RETAILMANAGE_SHIP'])"></el-switch>
            </el-form-item>
          </div>

          <div class="h s">
            <el-form-item label="收货地址" prop="provinceCode">
              <quick-select v-model="form.provinceCode" :label.sync="form.provinceName" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.cityCode = null; form.districtCode = null;" />
            </el-form-item>
            <el-form-item label-width="10px" prop="cityCode" style="width: 185px;">
              <quick-select v-model="form.cityCode" :label.sync="form.cityName" :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.provinceCode" style="width: 100%;" @change="form.districtCode = null" />
              <el-input disabled placeholder="请先选择省份" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="districtCode" style="width: 185px;">
              <quick-select v-model="form.districtCode" :label.sync="form.districtName" :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.cityCode" style="width: 100%;" />
              <el-input disabled placeholder="请先选择城市" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="customerAddress" class="flex">
              <el-input v-model="form.customerAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
          </div>

          <div class="h s">
            <el-form-item label="销售门店" prop="shopId">
              <quick-select v-model="form.shopId" filterable :disabled="changeLimited" url="api/shop" :disabled-option="checkShopDisabled" value-field="id" display-field="shopName" placeholder="选择销售门店" auto-select-first-option @change="form.salesmanId = null" style="width: 460px;" v-if="form.id" />
              <quick-select v-model="form.shopId" filterable :disabled="changeLimited" url="api/shop?enabled=true" value-field="id" display-field="shopName" placeholder="选择销售门店" auto-select-first-option @change="form.salesmanId = null" style="width: 460px;" v-else />
            </el-form-item>
            <el-form-item label="销售人员" label-width="100px">
              <div class="h c" v-if="form.shopId">
                <!-- <quick-select v-model="form.salesmanId" filterable style="width: 180px;" :url="`api/shop/users?shopId=${form.shopId}`" value-field="id" display-field="userRealName" placeholder="选择销售人员" @change="handleSalemanChange" /> -->
                <el-input v-model="form.salesmanName" :maxlength="40" clearable placeholder="请填写销售人员" style="width: 180px;" v-if="customizeSalesman" />
                <quick-select v-model="form.salesmanId" :label.sync="form.salesmanName" filterable clearable :url="`api/shop/users?shopId=${form.shopId}`" value-field="id" display-field="userRealName" placeholder="请选择销售人员" style="width: 180px;" v-else />
                <el-button style="margin-left: 5px;" @click="toggleCustomizeSalesman">{{customizeSalesman ? "选择" : "手填"}}</el-button>
              </div>
              <el-input disabled placeholder="请先选择销售门店" v-else style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="contractNo" label="合同号" label-width="100px">
              <el-input v-model.trim="form.contractNo" :maxlength="30" placeholder="请输入商场合同号" style="width: 180px;" @change="doCheckContractNo" />
            </el-form-item>
          </div>

          <el-form-item prop="items" label="销售商品">
            <template v-if="form.shopId">
              <div class="h c">
                <sku-selector request-url="api/shop/salesGoods" retail shelf @submit="handleGoodsAdd" />
                <div class="fc-e padding-0-10 fs-small" v-if="highPriceTip">注意：当前有销售价高于统一零售价的商品</div>
              </div>
              <el-table :data="form.items" row-key="id" :row-class-name="getRowClassName" empty-text="还没有添加任何商品">
                <el-table-column prop="code" label="商品编码" min-width="140" fixed />
                <el-table-column prop="erpCode" label="ERP编码" min-width="140" fixed />
                <el-table-column label="商品" min-width="220" fixed>
                  <div style="line-height: 1.5;" slot-scope="scope">
                    <div>{{ scope.row.goodsName }}</div>
                    <div class="fc-g">{{ $goodsSpecConvert(scope.row.specs) }}</div>
                  </div>
                </el-table-column>
                <el-table-column width="100" fixed>
                  <template slot-scope="scope">
                    <el-select size="mini" v-model="scope.row.itemType" @change="handleGoodaItemTypeChange(scope.row)" style="width: 100%;" v-if="$erp.enable()">
                      <el-option v-for="(v, k) in goodsItemTypes" :key="k" :label="v.label" :value="k" />
                    </el-select>
                    <el-select size="mini" v-model="scope.row.itemType" @change="handleGoodaItemTypeChange(scope.row)" style="width: 100%;" v-else>
                      <el-option v-for="(v, k) in goodsItemTypesDis" :key="k" :label="v.label" :value="k" />
                    </el-select>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
                <!-- <el-table-column prop="seriesName" label="系列" width="150" /> -->

                <el-table-column label="统一零售价" width="100" align="right">
                  <template slot-scope="scope">
                    <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                    <div v-else-if="scope.row._price.error" class="h c">
                      <span class="fc-e">{{ scope.row._price.error }}</span>
                      <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                        &nbsp;
                        <i class="el-icon-refresh"></i>
                      </a>
                    </div>
                    <div v-else>{{ $price(scope.row.price) }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="销售价" width="140">
                  <template slot-scope="scope">
                    <price-input v-model="scope.row.realPrice" :min="0" size="mini" style="width: 100%;" @change="calcRealPrice(scope.row)" />
                  </template>
                </el-table-column>
                <!-- <el-table-column label="折扣率" width="70" align="right" :formatter="calcDiscountPercent" /> -->
                <el-table-column label="折扣率" width="150" align="right">
                  <template slot-scope="scope">
                    <percent-input v-model="scope.row.discountRate" size="mini" :base="10000" style="width: 100%;" @change="handleChangeDiscountRate(scope.row)" />
                  </template>
                </el-table-column>

                <el-table-column label="数量" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.quantity" :min="0.01" :max="9999" :step="1" :precision="2" size="mini" @change="hasChanged = true; $checkNaN(scope.row, 'quantity')" controls-position="right" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="金额合计" width="90" align="center">
                  <template slot-scope="scope">{{ $price(scope.row.realPrice * scope.row.quantity) }}</template>
                </el-table-column>
                <el-table-column label="可售数量" width="100">
                  <template slot-scope="scope">
                    <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                    <div v-else-if="scope.row._stockInfo.error" class="h c">
                      <span class="fc-e">{{ scope.row._stockInfo.error }}</span>
                      <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                    </div>
                    <div v-else class="h c">
                      <b>{{ calcStock(scope.row) }}</b>
                      <el-popover placement="bottom" trigger="click">
                        <div class="h c">
                          <span>库存数量：{{ scope.row._stockInfo.values.warehouseCount }}</span>
                          <el-divider direction="vertical" />
                          <span>待发货数量：{{ scope.row._stockInfo.values.saleCount }}</span>
                          <el-divider direction="vertical" />
                          <span>在途数量：{{ scope.row._stockInfo.values.purchaseCount }}</span>
                        </div>
                        <!--  -->
                        <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo != null">
                          <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                            <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                              <template slot-scope="scope">
                                {{
                                scope.row.warehouseName != null ?
                                scope.row.warehouseName : '--'
                                }}
                              </template>
                            </el-table-column>
                            <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                              <template slot-scope="scope">
                                {{
                                scope.row.count != null ? scope.row.count : '--'
                                }}
                              </template>
                            </el-table-column>
                            <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                              <template slot-scope="scope">
                                {{
                                scope.row.occupy != null ? scope.row.occupy : '--'
                                }}
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                        <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="成本单价" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                  <template slot-scope="scope">
                    {{
                    scope.row._stockInfo.values.money ?
                    $price(scope.row._stockInfo.values.money) : '未知'
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="成本小计" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                  <template slot-scope="scope">
                    {{
                    scope.row._stockInfo.values.money ?
                    $price(scope.row._stockInfo.values.money * scope.row.quantity) : '未知'
                    }}
                  </template>
                </el-table-column>
                <!-- <el-table-column label="无需采购" width="70" align="center">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.isPurchased" :true-label="1" :false-label="0" />
                  </template>
                </el-table-column>-->

                <el-table-column label="现场提货" width="70" align="center">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.sendType" :true-label="0" :false-label="1" />
                  </template>
                </el-table-column>
                <el-table-column label="交货日期" width="155" align="center">
                  <template slot-scope="scope">
                    <el-date-picker v-model.trim="scope.row.deliveryDate" :clearable="false" size="mini" type="date" value-format="timestamp" placeholder="交货日期" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="库房" width="155">
                  <template slot-scope="scope">
                    <quick-select v-model="scope.row.warehouseId" filterable url="api/warehouse" placeholder="请选择发货库房" clearable />
                  </template>
                </el-table-column>

                <el-table-column label="参与活动" width="155">
                  <template slot-scope="scope">
                    <quick-select v-model="scope.row.shoPromotionId" :label.sync="scope.row.shoPromotionName" filterable clearable url="api/shoPromotion/available" placeholder="请选择活动" />
                  </template>
                </el-table-column>

                <el-table-column label="备注" width="150">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.info" size="mini" :maxlength="100" placeholder="最大100字符" />
                  </template>
                </el-table-column>

                <el-table-column width="50" fixed="right">
                  <div class="row-commands" slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                  </div>
                </el-table-column>
              </el-table>
            </template>
            <span v-else class="fc-g">请先选择一个门店</span>
          </el-form-item>

          <el-form-item label="统一操作">
            <el-button type="primary" @click="dialogSendVisible = true">设置发货时间</el-button>
            <el-button type="primary" @click="dialogSetStockVisible = true">设置库房</el-button>
            <el-button type="primary" @click="dialogSetSaleProjectVisible = true">设置活动</el-button>
          </el-form-item>

          <div class="h s">
            <el-form-item label="优惠券">
              <el-input v-model="form.couponRemark" :maxlength="50" placeholder="请输入优惠券名称" style="width: 370px" />
            </el-form-item>

            <div class="flex"></div>
            <!-- <el-form-item label="已付款">
                <b>￥{{(payed / 100).toFixed(2)}}</b>
            </el-form-item>-->
            <!-- <el-form-item prop="payType" label="支付方式">
                <el-radio-group v-model="form.payType">
                  <el-radio-button :label="0">线上支付</el-radio-button>
                  <el-radio-button :label="1">线下支付</el-radio-button>
                </el-radio-group>
            </el-form-item>-->
            <form-info-item label="商品数量" label-width="auto" style="margin-right: 30px;">
              <b>{{ totalQuantity }}</b>
            </form-info-item>
            <form-info-item label="订单总额">
              <b>{{ $price(count.total) }}</b>
              （商品金额: {{ $price(count.total + count.discount) }} - 优惠金额：{{ $price(count.discount) }}）
            </form-info-item>
            <div v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_REFUND']" style="margin-left: 24px;" v-if="form.id && payable">
              <el-button type="info" size="mini" @click="handlePayDetails">付款明细</el-button>
              <el-button type="primary" size="mini" @click="handlePayAdd">添加支付款项</el-button>
            </div>
          </div>
          <!-- <el-form-item prop="warehouseId" label="库房">
            <quick-select v-model="form.warehouseId" filterable url="api/warehouse" auto-select-first-option placeholder="请选择发货库房" style="width: 370px" />
          </el-form-item>-->
          <div class="h">
            <el-form-item prop="source" label="订单来源">
              <quick-select v-model="form.source" :options="orerSourceOptions" clearable display-field="name" value-field="name" filterable placeholder="请选择订单来源" style="width: 370px;" v-if="orerSourceOptions && orerSourceOptions.length" />
              <el-input v-model.trim="form.source" :maxlength="200" placeholder="请填写订单来源" style="width:370px" v-else />
            </el-form-item>
          </div>
          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model.trim="form.info" :maxlength="100" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="2" />
          </el-form-item>
          <el-form-item label="附件">
            <file-uploader :entity-id="form.fileId" folder="retail/attachments" accept multiple :max-count="9" :thumbnail-size="64" />
          </el-form-item>
        </el-form>
        <div class="c h">
          <div class="flex"></div>
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave" v-if="editableOther">保存</el-button>
          <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="form.id && !form.isPrepare && editableOther">保存并提交</el-button>
        </div>
      </div>

      <div key="previewer" v-else>
        <el-tabs v-model="previewItem" v-if="!auditable && !action">
          <el-tab-pane name="basic" label="订单信息" />
          <el-tab-pane name="audits" label="订单审核记录" />
          <el-tab-pane name="changes" label="订单变更记录" />
          <el-tab-pane name="consignment" label="发货通知" />
          <el-tab-pane name="outbound" label="出库信息" />
          <el-tab-pane name="return" label="退货信息" />
          <el-tab-pane name="purchase" label="采购信息" />
        </el-tabs>

        <div v-show="previewItem === 'basic'">
          <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="90px" label-suffix="：">
            <div class="h e sb">
              <div>
                <form-info-item label="审批备注" v-if="form.auditRemark">
                  <div :class="status[form.status].type">{{ form.auditRemark }}</div>
                </form-info-item>
                <form-info-item label="销售单号">{{ form.formCode }}</form-info-item>
                <form-info-item label="销售类型" v-if="$erp.enable()">{{ form.salesTypeName }}</form-info-item>
                <form-info-item label="预售订单" v-if="form.isPrepare">
                  <i class="la la-check"></i>
                </form-info-item>
                <form-info-item label="商场合同号">{{ form.contractNo }}</form-info-item>
                <form-info-item label="单据日期">{{ new Date(form.purchaseTime).format("yyyy/MM/dd") }}</form-info-item>
              </div>
              <div>
                <form-info-item label="导入订单" inline>
                  <el-tag v-if="form.isImport">是</el-tag>
                  <el-tag type="info" v-else>否</el-tag>
                </form-info-item>
                <form-info-item label="全款发货" inline>
                  <el-tag v-if="form.fullPaymentShip">是</el-tag>
                  <el-tag type="info" v-else>否</el-tag>
                </form-info-item>

                <form-info-item label="收货人">{{ form.customerName }}（{{ form.customerPhone }}）</form-info-item>
                <form-info-item label="收货地址">
                  {{ form.provinceName }}{{ form.cityName }}{{ form.districtName }}{{
                  form.customerAddress
                  }}
                </form-info-item>
                <!-- <form-info-item label="发货日期">{{form.deliveryDate?new Date(form.deliveryDate).format("yyyy/MM/dd"):''}}</form-info-item> -->
              </div>
            </div>
            <el-table border :data="form.items" row-key="id" highlight-current-row empty-text="当前销售单没有任何商品" style="margin: 8px 0;">
              <el-table-column prop="code" label="商品编码" min-width="140" fixed />
              <el-table-column prop="erpCode" label="ERP编码" min-width="140" fixed />
              <el-table-column label="商品" min-width="220" fixed>
                <div style="line-height: 1.5;" slot-scope="scope">
                  <el-tag :type="goodsItemTypes[scope.row.itemType || 'sale'].type" size="mini">
                    {{
                    goodsItemTypes[scope.row.itemType || 'sale'].label
                    }}
                  </el-tag>
                  <div>{{ scope.row.goodsName }}</div>
                  <div class="fc-g">{{ $goodsSpecConvert(scope.row.specs) }}</div>
                </div>
              </el-table-column>
              <!-- <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
              <el-table-column prop="brandName" label="品牌" width="120" />-->

              <el-table-column prop="price" label="统一零售价" width="100" align="right" :formatter="$price" />
              <el-table-column prop="realPrice" label="实际单价" width="100" align="right" :formatter="$price" />
              <el-table-column prop="discountAmount" label="优惠金额" width="100" align="right" :formatter="$price" />
              <el-table-column label="折扣率" width="70" align="right" :formatter="calcDiscountPercent" />
              <el-table-column prop="quantity" label="数量" align="right" width="70" />
              <el-table-column label="金额合计" width="90" align="center">
                <template slot-scope="scope">
                  {{
                  $price(scope.row.realPrice * (scope.row.quantity -
                  scope.row.cancelCount))
                  }}
                </template>
              </el-table-column>
              <el-table-column label="已发货数量" width="100" align="center">
                <template slot-scope="scope">{{ scope.row.sendCount || 0 }}</template>
              </el-table-column>
              <el-table-column label="已取消数量" width="100" align="center">
                <template slot-scope="scope">{{ scope.row.cancelCount || 0 }}</template>
              </el-table-column>

              <el-table-column label="本次发货数量" width="120" align="center" v-if="action === 'outbound'">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.currentSendCount" :min="0" :max="(scope.row.quantity - scope.row.sendCount - scope.row.deliveryCount - scope.row.cancelCount)" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row, 'currentSendCount', 0)" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="发货通知数量" width="100" align="center">
                <template slot-scope="scope">{{ scope.row.deliveryCount || 0 }}</template>
              </el-table-column>
              <el-table-column prop="warehouseId" label="库房" width="175" :formatter="r => { return getWarehouse(r.warehouseId) }" />
              <el-table-column prop="shoPromotionName" label="参与活动" show-overflow-tooltip />
              <!-- <template slot-scope="scope">
                <quick-select v-model="scope.row.warehouseId" filterable url="api/warehouse" placeholder="请选择发货库房" />
              </template>
              </el-table-column>-->

              <el-table-column label="库存" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                  <div v-else-if="scope.row._stockInfo.error" class="h c">
                    <span class="fc-e">{{ scope.row._stockInfo.error }}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <div v-else class="h c">
                    <b>{{ scope.row._stockInfo.values.warehouseCount }}</b>
                    <el-popover placement="bottom" trigger="click">
                      <div class="h c">
                        <span>库存数量：{{ scope.row._stockInfo.values.warehouseCount }}</span>
                        <el-divider direction="vertical" />
                        <span>待发货数量：{{ scope.row._stockInfo.values.saleCount }}</span>
                        <el-divider direction="vertical" />
                        <span>在途数量：{{ scope.row._stockInfo.values.purchaseCount }}</span>
                      </div>

                      <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo != null">
                        <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                          <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                            <template slot-scope="scope">
                              {{
                              scope.row.warehouseName != null ?
                              scope.row.warehouseName : '--'
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">
                              {{
                              scope.row.count != null ? scope.row.count : '--'
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">
                              {{
                              scope.row.occupy != null ? scope.row.occupy : '--'
                              }}
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>              

              <!-- <el-table-column label="无需采购/已采购" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isPurchased" :true-label="1" :false-label="0" disabled />
              </template>
              </el-table-column>-->

              <el-table-column label="现场提货" width="70" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.sendType" :true-label="0" :false-label="1" disabled />
                </template>
              </el-table-column>
              <el-table-column label="交货日期" width="90" align="center">
                <template slot-scope="scope">
                  {{
                  scope.row.deliveryDate ? new
                  Date(scope.row.deliveryDate).format('yyyy/MM/dd') : ''
                  }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="小计" width="100" align="right">
                <template slot-scope="scope">
                  <b>{{((scope.row.price * scope.row.quantity - scope.row.discountAmount) / 100).toFixed(2)}}</b>
                </template>
              </el-table-column>-->
              <el-table-column prop="changeFormCode" label="变更单号" width="150" />
              <el-table-column prop="info" label="备注" width="150" show-overflow-tooltip />
              <el-table-column width="50" fixed="right">
                <div class="row-commands" slot-scope="scope" v-if="scope.row.changeType !== 'CANCEL'">
                  <template v-if="rowClosable">
                    <el-button type="text" size="mini" @click="handleCloseItem(scope.row)" v-if="scope.row.quantity - scope.row.sendCount - scope.row.cancelCount > 0">关闭</el-button>
                    <el-button type="text" size="mini" @click="handleDoClose(scope.row)" v-else-if="$erp.enable() && scope.row.sendCount > 0">关闭</el-button>
                  </template>
                  <template v-else-if="checkPermission(['RETAILMANAGE_ALL', 'RETAILMANAGE_CLOSE']) && $erp.enable() && scope.row.quantity - scope.row.cancelCount != 0">
                    <el-button type="text" size="mini" @click="handleDoClose(scope.row)" v-if="form.status === 'sended'">关闭</el-button>
                  </template>
                </div>
              </el-table-column>
              <el-table-column label="成本单价" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">
                  {{
                  scope.row.purchasePrice ? $price(scope.row.purchasePrice) : '未知'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="成本小计" width="100px" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">
                  {{
                  scope.row.purchasePrice ? $price(scope.row.purchasePrice *
                  (scope.row.quantity - scope.row.cancelCount)) : '未知'
                  }}
                </template>
              </el-table-column>
            </el-table>
            <div class="h s">
              <div class="flex">
                <form-info-item label="优惠券">{{ form.couponRemark || "无" }}</form-info-item>
                <form-info-item label="订单来源">{{ form.source || "无" }}</form-info-item>
              </div>
              <div class="v e">
                <div class="h">
                  <form-info-item label="商品数量" label-width="auto" style="margin-right: 30px;">
                    <b>{{ totalQuantity }}</b>
                  </form-info-item>
                  <form-info-item label="订单总额" label-width="auto" style="margin-left:30px;">
                    <b>{{ $price(count.total) }}</b>
                  </form-info-item>                
                  <form-info-item label="已收款" label-width="auto" style="margin-left:30px;">
                    <b>{{ $price(payed) }}</b>
                  </form-info-item>
                  <form-info-item label="待收款" label-width="auto" style="margin-left:30px;">
                    <b>{{ $price(count.total - payed) }}</b>
                  </form-info-item>
                  <form-info-item label="已确认到帐" label-width="auto" style="margin-left:30px;">
                    <b>{{ $price(form.inAccountMoney) }}</b>
                  </form-info-item>
                  <form-info-item label="成本合计" label-width="auto" style="margin-left:30px;" v-if="checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
                    <b>{{ $price(totalCostPay) }}</b>
                  </form-info-item>
                </div>
                <div class="h c" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_REFUND']" v-if="payable">
                  <el-button type="info" size="mini" @click="handlePayDetails">付款明细</el-button>
                  <el-button type="primary" @click="handlePayAdd" size="mini" v-if="!auditable && !action">添加支付款项</el-button>
                </div>
              </div>
            </div>

            <form-info-item label="备注">{{ form.info || "无" }}</form-info-item>
            <form-info-item label="附件">
              <file-uploader :entity-id="form.fileId" folder="retail/attachments" accept empty-text="没有上传附件" multiple readonly :max-count="9" :thumbnail-size="64" />
            </form-info-item>
          </el-form>
          <template v-if="auditable && (form.status === 'submit' || form.status === 'auditing')">
            <el-divider content-position="left">审批</el-divider>
            <el-form ref="auditForm" :model="auditForm" :rules="rules" label-position="top" v-if="auditForm">
              <div class="h e">
                <el-form-item label="审批结果">
                  <el-select v-model="auditForm.status" style="width: 400px;">
                    <el-option value="pass" label="同意当前订单的价格或优惠" />
                    <el-option value="callback" label="不同意当前订单的价格或优惠，退回订单进行修改" />
                    <el-option value="fail" label="拒绝当前订单的价格或优惠，同时将该订单作废" />
                  </el-select>
                </el-form-item>
                <el-form-item prop="auditRemark" label="审批备注" class="flex" style="margin-left: 20px; margin-right: 20px;">
                  <el-input v-model="auditForm.auditRemark" :maxlength="100" />
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" :loading="submiting" @click="doAudit">提交审批</el-button>
                  <el-button @click="dialog = false">取消</el-button>
                </el-form-item>
              </div>
            </el-form>
          </template>
          <template v-else-if="action === 'outbound'">
            <el-divider />
            <el-form ref="outboundForm" :model="outboundForm" :rules="outRules" label-position="left">
              <div class="h e">
                <el-form-item prop="warehouseId" label="发货库房：" label-width="92px">
                  <quick-select v-model="outboundForm.warehouseId" filterable url="api/warehouse" placeholder="请选择发货库房" style="width: 240px;" />
                </el-form-item>
                <el-form-item style="margin-left: 20px;">
                  <el-button type="primary" :loading="submiting" @click="doOutbound">确认发货</el-button>
                  <el-button @click="dialog = false">取消</el-button>
                </el-form-item>
              </div>
            </el-form>
          </template>

          <!-- <template v-else-if="action === 'report'">
          <el-divider />
          <div class="h c">
            <div class="padding-0-10">上报过程可能耗费较长时间，请耐心等候。</div>
            <el-button type="primary" :loading="reporting" @click="doReport">开始上报</el-button>
            <el-button @click="dialog = false">取消</el-button>
          </div>
          </template>-->

          <template v-else>
            <el-divider />
            <div class="h">
              <form-info-item label="当前状态" label-position="right" label-suffix=":">
                <dot same :type="status[form.status].type">{{ status[form.status].label }}</dot>
              </form-info-item>
              
              <template v-if="form.status !== 'input'">
                <form-info-item label="提交时间" label-position="right" label-suffix=":" style="margin-left:20px">
                  {{
                  new
                  Date(form.submitAt).format()
                  }}
                </form-info-item>
                <form-info-item label="提交人" label-position="right" label-suffix=":" style="margin-left:20px">
                  {{
                  form.submitBy
                  }}
                </form-info-item>
                <form-info-item label="销售人员" label-position="right" label-suffix=":" style="margin-left:20px">
                {{form.salesmanName}}
              </form-info-item>
              </template>
              <template v-if="form.sendNotice">
                <form-info-item label="供应来源" label-position="right" label-suffix=":" style="margin-left:20px" v-if="form.supplySource">
                  {{
                  form.supplySource === 1 ? '内部组织' : form.supplySource === 4 ? '当前组织' :
                  ''
                  }}
                </form-info-item>
                <form-info-item label="自动审核" label-position="right" label-suffix=":" style="margin-left:20px">
                  {{
                  form.needApprove ? "是" : "否"
                  }}
                </form-info-item>
                <form-info-item label="上报人" label-position="right" label-suffix=":" style="margin-left:20px" v-if="form.sendNoticeBy">
                  {{form.sendNoticeBy}}  
                </form-info-item>
                <form-info-item label="上报时间" label-position="right" label-suffix=":" style="margin-left:20px">
                  {{
                  new Date(form.sendNoticeTime).format()
                  }} 
                </form-info-item>
              </template>
              <template v-if="form.status === 'submit' || form.status === 'auditing'">
                <form-info-item label="待审角色" label-position="right" label-suffix=":" style="margin-left:20px">
                  {{
                  auditRoleName
                  }}
                </form-info-item>
              </template>
              <div class="flex"></div>
              <el-button size="mini" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_APPROVE']" type="primary" @click="auditable = true" v-if="disableAudit && (form.status === 'submit' || form.status === 'auditing')">审批</el-button>
            </div>
          </template>
        </div>

        <div v-show="previewItem === 'audits'">
          <el-table border :data="auditStore" empty-text="暂无审核记录">
            <el-table-column label="操作时间" :formatter="r => {return new Date(r.auditTime).format(); }" width="150" />
            <el-table-column label="操作人" prop="userName" width="120" />
            <el-table-column label="操作类型" width="100">
              <template slot-scope="scope">{{scope.row.auditType===null?auditTypes['audit']: auditTypes[scope.row.auditType]}}</template>
            </el-table-column>
            <el-table-column label="处理结果" width="100">
              <template slot-scope="scope">
                <dot same :type="status[scope.row.status].type" v-if="scope.row.auditType===null || scope.row.auditType==='audit'">{{status[scope.row.status].label}}</dot>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column label="处理备注" prop="remark" min-width="240" />
          </el-table>
        </div>

        <template v-if="!auditable && !action">
          <change-record :order-id="form.id" :warehouses="WarehouseStore" @change-audited="handleChangeAudited" v-show="previewItem === 'changes'" />
          <consignment-note :target-depend-form-code="form.formCode" v-show="previewItem === 'consignment'" />
          <outbound :target-depend-form-code="form.formCode" v-show="previewItem === 'outbound'" />
          <retail-return :target-depend-form-code="form.formCode" v-show="previewItem === 'return'" />
          <purchase-order :depend-retail-form-code="relationPurchaseOrderCodes" v-show="previewItem === 'purchase'" />
        </template>
      </div>
    </template>

    <pays ref="pays" :editable="!auditable && !action" />
    <pay-form ref="payForm" @localc-submit="handleGoodsAddSubmit" @remote-submit="handleGoodsAddSubmit" />
    <do-close-form ref="doCloseForm" />

    <el-dialog title="设置发货时间" :visible.sync="dialogSendVisible" width="30%" append-to-body>
      <span>
        <el-date-picker type="date" v-model="g_sendDate" format="yyyy-MM-dd" value-format="timestamp" style="width: 180px" placeholder="计划发货时间" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSendVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSetSendDate()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置发货库房" :visible.sync="dialogSetStockVisible" width="30%" append-to-body>
      <span>
        <quick-select v-model="g_stock" filterable url="api/warehouse" placeholder="请选择发货库房" clearable />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSetStockVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSetStock()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置活动" :visible.sync="dialogSetSaleProjectVisible" width="30%" append-to-body>
      <span>
        <quick-select v-model="g_saleproject" filterable clearable url="api/shoPromotion/available" placeholder="请选择活动" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSetSaleProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSetSaleProject()">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>

  <!-- <el-dialog :visible.sync="auditVisible" append-to-body title="审批确认" :close-on-click-modal="false" @closed="auditForm = null" width="640px">
      <el-form ref="auditForm" :model="auditForm" :rules="rules" label-position="right" label-suffix=":" label-width="84px" v-if="auditForm">
        <el-form-item label="审批情况">{{auditModeTypes[auditForm.status]}}</el-form-item>
        <el-form-item prop="auditRemark" label="审批备注">
          <el-input type="textarea" v-model="auditForm.auditRemark" :maxlength="100" resize="none" :rows="4" required />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="auditVisible = false">取消</el-button>
        <el-button :loading="auditing" type="primary" @click="execAudit" :disabled="!auditForm || !auditForm.auditRemark">提交</el-button>
      </div>
  </el-dialog>-->
</template>

<script>
import checkPermission from "@/utils/permission";
import { mapGetters } from "vuex";
import {
  add,
  edit,
  get,
  submit,
  audit,
  send,
  sendNotice,
  changeLimit,
  checkContractNo,
} from "@/api/shopOrder";
import { getSkuRetailPrice } from "@/api/goods";

import { getGoodsByShopId } from "@/api/warehouse";
import skuSelector from "@/views/assembly/skuSelect";
import { getStatementType } from "@/api/capitalPool";
import request from "@/utils/request";

import pays from "./pays";
import payForm from "./pay-form";
import doCloseForm from "./doCloseForm";
import consignmentNote from "../consignment-note/index";
import outbound from "../outbound/index";
import retailReturn from "../return/index";
import purchaseOrder from "../../purchase/order/index";
import changeRecord from "./changeRecord";
import customerPage from "@/views/store/customer/index";

export default {
  components: {
    pays,
    payForm,
    doCloseForm,
    skuSelector,
    consignmentNote,
    outbound,
    retailReturn,
    purchaseOrder,
    changeRecord,
    customerPage,
  },
  props: {
    payable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const checkItemInfos = function (rule, value, callback) {
      let err = null;
      if (value && value.length) {
        for (let i = 0, l = value.length; i < l; i++) {
          if (!value[i].deliveryDate) {
            err = "请选择交货日期";
            break;
          }
        }
      }
      if (err) {
        callback(new Error(err));
      } else {
        callback();
      }
    };

    return {
      dialogSendVisible: false,
      dialogSetStockVisible: false,
      dialogSetSaleProjectVisible: false,
      g_sendDate: null,
      g_stock: null,
      g_saleproject: null,
      loading: false,
      reporting: false,
      saving: false,
      submiting: false,
      dialog: false,
      action: null,
      auditable: false,
      changeLimited: true,
      now: null,
      WarehouseStore: null, //查看时库房名称
      customizeSalesman: false,
      previewItem: "basic",
      relationPurchaseOrderCodes: [],
      purchaseTimeOptions: {
        disabledDate: (d) => {
          let nd = d.getTime();
          return nd > this.now;
        },
      },
      auditForm: {
        id: null,
        status: "pass",
        auditRemark: "",
      },
      auditStore: null,
      outboundForm: {
        id: null,
        warehouseId: null,
        items: null,
      },
      goodsItemTypes: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
        simple: { label: "出样", type: "info" },
      },
      goodsItemTypesDis: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
      },
      auditTypes: {
        audit: "审核订单",
        submit: "提交订单",
        cancelAudit: "反审核订单",
        closeRow: "关闭订单行",
        close: "关闭订单",
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
        closedPart: { key: "closedPart", label: "部分已关闭", type: "info" },
      },
      form: null,
      orerSourceOptions: [],
      hasChanged: false,
      disableAudit: false,
      outRules: {
        warehouseId: [
          { required: true, message: "请选择发货库房", trigger: "change" },
        ],
      },
      rules: {
        salesTypeId: [
          { required: true, message: "请选择销售类型", trigger: "change" },
        ],
        provinceCode: [
          { required: true, message: "请选择省份", trigger: "change" },
        ],
        cityCode: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
        districtCode: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        customerAddress: [
          { required: true, message: "收货地址为必填项", trigger: "blur" },
        ],
        customerName: [
          { required: true, message: "请填写客户名称", trigger: "blur" },
        ],
        shopId: [{ required: true, message: "请选择门店", trigger: "blur" }],
        salesmanId: [{ required: true, message: "请选择销售人员" }],
        customerPhone: [
          { required: true, message: "请填写客户手机号码" },
          {
            pattern: /^\d{11}$/gi,
            message: "手机号码格式不正确",
          },
        ],
        items: [
          { required: true, message: "请选择要销售的商品" },
          { type: "array", min: 1, message: "请选择要销售的商品" },
          { validator: checkItemInfos },
        ],
        prePay: [
          { required: true, message: "请填写已付款金额", trigger: "blur" },
        ],
        auditRemark: [{ required: true, message: "请填写审批备注信息" }],

        contractNo: [{ required: true, message: "请填写合同号" }],
      },
      customerQueryDefine: {
        keyword: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    hasChangeRecord() {
      if (this.form && this.form.items && this.form.items.length) {
        return this.form.items.filter((o) => !!o.changeFormCode).length > 0;
      }
      return false;
    },
    editable() {
      return (
        !this.auditable &&
        this.form &&
        this.checkPermission(["RETAILMANAGE_ALL", "RETAILMANAGE_EDIT"]) &&
        (this.form.status === "input" || this.form.status === "callback")
      );
    },
    editableOther() {
      return (
        this.checkPermission(["RETAILMANAGE_ALL", "RETAILMANAGE_EDIT_OTHER"]) ||
        this.form.createById === this.user.id ||
        !this.form.createById
      );
    },
    rowClosable() {
      return (
        this.form &&
        this.checkPermission(["RETAILMANAGE_ALL", "RETAILMANAGE_CLOSE"]) &&
        (this.form.status === "pass" ||
          this.form.status === "unsend" ||
          this.form.status === "sendPart" ||
          this.form.status === "closedPart")
      );
    },
    title() {
      if (this.loading) {
        return "加载中…";
      } else if (this.form) {
        if (this.editable) {
          return this.form.id ? "修改销售单" : "新增销售单";
        } else if (this.auditable) {
          return "审批销售单";
        } else if (this.action === "outbound") {
          return "新建发货";
        } else {
          return "查看销售单";
        }
      }
      return "";
    },
    count() {
      let m = {
        total: 0,
        discount: 0,
      };
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          let dm = o.price - o.realPrice;
          if (dm < 0) dm = 0;
          m.total += o.realPrice * (o.quantity - o.cancelCount);
          m.discount += dm * (o.quantity - o.cancelCount);
        });
        this.form._totalAmount = m.total;
        this.form._discountAmount = m.discount;
      }
      return m;
    },
    // 成本合计
    // scope.row._stockInfo.values.money*scope.row.quantity
    totalCostPay() {
      let cp = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          cp += o._stockInfo.values.money
            ? o._stockInfo.values.money * (o.quantity - o.cancelCount)
            : 0 * o.quantity;
        });
      }
      return cp;
    },
    totalQuantity() {
      let q = 0;
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          q += o.quantity || 0;
        });
      }
      return q;
    },
    payed() {
      let c = 0;
      if (this.form && this.form.payItems && this.form.payItems.length) {
        this.form.payItems.forEach((o) => {
          if (o.feeType === "orderForm") c += o.amount;
        });
      }
      return c;
    },
    highPriceTip() {
      let r = false;
      if (this.form && this.form.items && this.form.items.length) {
        for (let i = 0; i < this.form.items.length; i++) {
          let o = this.form.items[i];
          if (o && o.price != null && o.price < o.realPrice) {
            r = true;
            break;
          }
        }
      }
      return r;
    },
  },
  mounted() {
    request({
      url: "api/warehouse",
      method: "get",
      params: {
        page: 0,
        size: 1000,
      },
    }).then((res) => {
      if (res) {
        this.WarehouseStore = res.content;
      }
    });
    this.getShoOrderSource();
  },
  methods: {
    checkPermission,
    checkShopDisabled(shop) {
      return shop.enabled !== true;
    },
    getShoOrderSource() {
      request({
        url: "api/shoOrderSource",
        method: "get",
        params: {
          enabled: true,
          page: 0,
          size: 9999,
          sort: "createAt,desc",
        },
      }).then((res) => {
        if (res && res.content && res.content.length > 0) {
          this.orerSourceOptions = res.content;
          if (this.$erp.enable()) {
            this.$set(this.rules, "source", [
              { required: true, message: "请选择订单来源" },
            ]);
          }
        }
      });
    },
    getRowClassName(scope) {
      return scope.row.price != null && scope.row.price < scope.row.realPrice
        ? "el-table--wraning-row"
        : "";
    },
    doCheckContractNo() {
      if (this.form && !this.form.id && this.form.contractNo) {
        checkContractNo(this.form.contractNo).then((res) => {
          if (!res) {
            this.$alert("该合同号已存在，建议进行更换。", "系统提示", {
              type: "warning",
            });
          }
        });
      }
    },
    genGoodsShipDatePickerOptions(row) {
      let minDate = row._minDeliveryDate;
      return {
        disabledDate: (date) => {
          return date.getTime() < minDate;
        },
      };
    },

    getWarehouse(warehouseId) {
      let wName = null;
      if (warehouseId) {
        (this.WarehouseStore || []).forEach((o) => {
          if (warehouseId == o.id) {
            wName = o.name;
          }
        });
      }
      return wName;
    },
    handleClosed() {
      this.form = null;
      this.changeLimited = true;
      this.auditStore = null;
    },
    handleSalemanChange(k, v, obj) {
      this.form.salesmanName = obj.userRealName;
    },
    handleChangeDiscountRate(row) {
      this.hasChanged = true;
      row.realPrice = row.price * (row.discountRate / 10000);
    },
    handleGoodaItemTypeChange(row) {
      if (row.itemType === "sale") {
        this.loadGoodsPrice(row);
      } else {
        row.realPrice = 0;
        row.price = 0;
      }
    },

    handleCustomerSelect(a, b, c) {
      if (this.form && c) {
        Object.assign(this.form, {
          customerName: c.name,
          customerPhone: c.phone,
          provinceCode: c.provinceCode,
          provinceName: c.provinceName,
          cityCode: c.cityCode,
          cityName: c.cityName,
          districtCode: c.districtCode,
          districtName: c.districtName,
          customerAddress: c.address,
        });
      }
    },
    // handlePurchaseTimeChange() {
    //   this.form.items.forEach(o => {
    //     if (o.sendType === 0) {
    //       this.calcMinDeliveryDate(o);
    //     }
    //   });
    // },
    calcRealPrice(row) {
      this.hasChanged = true;
      row.discountRate = (row.realPrice / row.price) * 10000;
    },
    calcDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0 || (row.price === 0 && row.realPrice === 0)) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcStock(row) {
      let r =
        row._stockInfo.values.warehouseCount - row._stockInfo.values.saleCount;
      if (r < 0) r = 0;
      return r.toFixed(2);
    },
    // calcMinDeliveryDate(o) {
    //   if(o.sendType === 0) {
    //     let d = new Date(this.form && this.form.purchaseTime ? this.form.purchaseTime : this.$now.get());
    //     o._minDeliveryDate = new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
    //     o.deliveryDate = o._minDeliveryDate;
    //   } else {
    //     request({
    //       url: "api/workday",
    //       method: "get",
    //       params: {
    //         days: o.deliveryCycle || 7
    //       }
    //     }).then(res => {
    //       o._minDeliveryDate = res;
    //       if (!o.deliveryDate || o.deliveryDate < res) o.deliveryDate = res;
    //     });
    //   }
    // },
    doAudit() {
      this.submiting = true;
      audit(this.auditForm)
        .then((res) => {
          Object.assign(this.form, res);
          this.$message({
            type: "success",
            message: "提交审批成功!",
          });
          this.dialog = false;
          this.$parent.init();
        })
        .finally((_) => {
          this.submiting = false;
        });
    },
    doReport() {
      this.reporting = true;
      sendNotice({
        id: this.form.id,
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "上报成功!",
          });
          this.dialog = false;
          this.$parent.init();
        })
        .finally((_) => {
          this.reporting = false;
        });
    },
    doOutbound() {
      this.$refs.outboundForm &&
        this.$refs.outboundForm.validate().then((_) => {
          let items = [];
          (this.form.items || []).forEach((o) => {
            if (
              typeof o.currentSendCount === "number" &&
              o.currentSendCount > 0
            ) {
              items.push({
                id: o.id,
                goodsId: o.goodsId,
                currentSendCount: o.currentSendCount,
              });
            }
          });
          if (items && items.length) {
            this.submiting = true;
            this.outboundForm.items = items;
            send(this.outboundForm)
              .then((_) => {
                this.$notify({
                  title: "发货成功",
                  type: "success",
                  duration: 2500,
                });
                this.resetForm(this.form, null, "outbound");
                this.dialog = false;
                this.$parent.init();
              })
              .finally((_) => {
                this.submiting = false;
              });
          } else {
            this.$alert("请设置要发货的商品以及发货数量。", "系统提示");
          }
        });
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          let pd = new Date(this.form.purchaseTime).format("yyyy-MM-dd"),
            bts = [];
          this.form.items.forEach((item, index) => {
            if (new Date(item.deliveryDate).format("yyyy-MM-dd") < pd) {
              bts.push(
                item.goodsName +
                  (item.specs
                    ? `（${this.$goodsSpecConvert(item.specs)}）`
                    : "")
              );
            }
            item.displayNo = index;
          });

          if (bts && bts.length) {
            this.$alert(
              `以下商品的交货日期小于单据日期：<br /><b class="fc-e">${bts.join(
                "<br />"
              )}</b>`,
              "系统提示",
              {
                type: "warning",
                dangerouslyUseHTMLString: true,
                showClose: false,
              }
            ).then(() => {
              this.execSave(callback);
            });
          } else {
            this.execSave(callback);
          }
        });
    },
    execSave(callback) {
      (this.form.id ? edit : add)(this.form)
        .then((res) => {
          this.hasChanged = false;
          if (res) {
            this.resetForm(res);
          }
          if (typeof callback === "function") callback();
          else {
            this.$parent.init();
            this.$notify({
              title: `保存销售单成功`,
              type: "success",
              duration: 2500,
            });
          }
        })
        .finally((_) => {
          this.saving = false;
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm(
          "提交销售单后将不能再进行修改，确定要提交吗？",
          "提交确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true,
          }
        ).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            submit(this.form.id)
              .then((res) => {
                this.$notify({
                  title: `提交销售单成功`,
                  type: "success",
                  duration: 2500,
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    handlePayDetails() {
      if (this.hasChanged) {
        this.handleUnablePay();
      } else {
        this.$refs.pays && this.$refs.pays.show(this.form, this.form.payItems);
      }
    },
    handlePayAdd() {
      if (this.hasChanged) {
        this.handleUnablePay();
      } else {
        this.$refs.payForm && this.$refs.payForm.resetForm(this.form);
      }
    },
    handleUnablePay() {
      this.$alert(
        "当前订单的金额或数量发生了改变，请先保存订单后再操作退/付款相关操作。",
        "系统提示",
        { type: "warning" }
      );
    },
    handleDoClose(data) {
      this.$refs.doCloseForm && this.$refs.doCloseForm.resetForm(data);
    },
    handleCloseItem(row) {
      if (
        this.rowClosable &&
        row &&
        row.quantity - row.sendCount - row.cancelCount > 0
      ) {
        this.$confirm("确定要关闭当前行吗？", "操作确认", {
          type: "warning",
        }).then((_) => {
          request({
            url: "api/shop/form/closeRow",
            method: "post",
            data: {
              id: row.id,
            },
          }).then((res) => {
            this.$notify({
              title: "关闭行成功",
              type: "success",
              duration: 2500,
            });
            this.resetForm(this.form);
            this.$parent.init();
          });
        });
      }
    },
    handleGoodsAddSubmit(data) {
      this.form.payItems.push(data);
    },

    toggleCustomizeSalesman() {
      this.customizeSalesman = !this.customizeSalesman;
      this.form.salesmanId = "";
      this.form.salesmanName = "";
    },
    resetForm(form, auditable = false, action = null) {
      this.now = this.$now.get();
      this.hasChanged = false;
      this.dialog = true;
      this.auditable = auditable;
      this.action = action;
      this.previewItem = "basic";
      this.relationPurchaseOrderCodes = [];
      if (form && form.id) {
        this.disableAudit = form.allowCurrentAudit;
        this.auditRoleName = form.auditRoleName;
        this.auditForm.id = form.id;
        this.outboundForm.id = form.id;
        this.loading = true;
        get(form.id)
          .then((res) => {
            let d = new Date(this.$now.get());
            let _minDeliveryDate = new Date(
              d.getFullYear(),
              d.getMonth(),
              d.getDate() + 7
            ).getTime();
            if (!res.fileId) res.fileId = this.$uuid();
            (res.payItems || []).forEach((pi) => {
              pi._id = this.$uuid();
            });
            let rpocs = [];
            (res.items || []).forEach((o) => {
              if (o.purchaseFormCode && rpocs.indexOf(o.purchaseFormCode) < 0)
                rpocs.push(o.purchaseFormCode);
              if (o.deliveryCount === null) {
                o.deliveryCount = 0;
              }
              if (o.price) {
                o.discountRate = (o.realPrice / o.price) * 10000;
              } else {
                o.discountRate = 10000;
              }
              o._price = {
                loading: false,
                error: null,
              };
              o._stockInfo = {
                loading: false,
                error: false,
                values: null,
              };
              this.loadStockInfo(o, res.shopId);
              if (action === "outbound")
                o.currentSendCount = o.quantity - o.sendCount;
            });
            this.customizeSalesman = !res.salesmanId;
            this.relationPurchaseOrderCodes = rpocs;
            this.form = res;

            // if (this.form.status === "input" || this.form.status === "callback") {
            //   (this.form.items || []).forEach(o => {
            //     this.calcMinDeliveryDate(o);
            //   });
            // }

            this.getAuditInfo();
          })
          .finally((_) => {
            this.loading = false;
          });

        changeLimit(form.id).then((res) => {
          this.changeLimited = !res;
        });
      } else {
        this.changeLimited = false;
        this.customizeSalesman = false;
        this.form = {
          items: [],
          source: null,
          warehouseId: null,
          formCode: null,
          isPrepare: false,
          salesTypeId: null,
          purchaseTime: this.now,
          customerId: null,
          customerName: null,
          customerPhone: null,
          provinceCode: this.user.provinceCode,
          provinceName: null,
          cityCode: this.user.cityCode,
          cityName: null,
          districtCode: null,
          districtName: null,
          customerAddress: null,
          prePay: 0,
          payType: 1,
          payItems: [],
          couponRemark: "",
          fileId: this.$uuid(),
          info: null,
          auditRemark: null,
          status: "input",
          shopId: null,
          fullPaymentShip: this.user.fullPaymentShip,
        };
      }
    },
    loadStockInfo(g, shopId = null) {
      if (g && g.goodsId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        g._stockInfo.values = {};
        let _purchasePrice = !(g.purchasePrice && g.purchasePrice > 0);
        getGoodsByShopId(g.goodsId, shopId || this.form.shopId, _purchasePrice)
          .then((res) => {
            g._stockInfo.values = res;
            if (res.money && res.money >= 0 && this.editable) {
              g.purchasePrice = res.money;
            } else {
              g._stockInfo.values.money = g.purchasePrice;
            }
          })
          .catch((err) => {
            g._stockInfo.error = "获取失败";
          })
          .finally((_) => {
            g._stockInfo.loading = false;
          });
      }
    },
    loadGoodsPrice(row) {
      row._price.loading = true;
      row._price.error = null;
      getSkuRetailPrice(this.form.shopId, row.goodsId)
        .then((res) => {
          if (isNaN(res)) {
            row._price.error = "获取失败";
            row.price = 0;
            row.realPrice = 0;
          } else {
            row.realPrice = row.price = parseInt(res);
          }
        })
        .catch((err) => {
          row._price.error = "获取失败";
          row.price = 0;
          row.realPrice = 0;
        })
        .finally((_) => {
          row._price.loading = false;
        });
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        this.hasChanged = true;
        let ds = this.form.items || [];
        let now = new Date(this.now);
        list.forEach((o) => {
          let _no = {
            id: this.$uuid(),
            discountRate: 10000,
            spuId: o.spuId,
            goodsId: o.id,
            brandId: o.brandId,
            brandName: o.brandName,
            seriesId: o.seriesId,
            seriesName: o.seriesName,
            goodsName: o.name,
            // deliveryCycle: o.deliveryCycle || 7,
            // _minDeliveryDate: null,
            deliveryDate: null,
            specs: o.specs,
            itemType: "sale",
            price: 0,
            realPrice: 0,
            _price: {
              loading: false,
              error: null,
            },
            _stockInfo: {
              loading: false,
              error: false,
              values: null,
            },
            code: o.code,
            erpCode: o.erpCode,
            quantity: 1,
            cancelCount: 0,
            sendType: 1,
            discountAmount: 0,
            info: "",
            warehouseId: null,
            shoPromotionId: null,
          };
          let dc = o.deliveryCycle || 7;
          _no.deliveryDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + dc
          ).getTime();
          ds.push(_no);
          this.loadGoodsPrice(_no);
          this.loadStockInfo(_no);
          // this.calcMinDeliveryDate(_no);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.id === row.id;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
    getAuditInfo() {
      if (this.form && this.form.id) {
        request({
          url: "api/shop/form/audits/" + this.form.id,
          method: "get",
        }).then((res) => {
          this.auditStore = res;
        });
      }
    },
    calcTotalPrice() {
      scope.row.realPrice * scope.row.realPricequantity;
    },

    handleSetSendDate() {
      if (this.form && this.form.items) {
        this.form.items.forEach((o) => {
          o.deliveryDate = this.g_sendDate;
        });
      }
      this.dialogSendVisible = false;
    },
    handleSetStock() {
      if (this.form && this.form.items) {
        this.form.items.forEach((o) => {
          o.warehouseId = this.g_stock;
        });
      }
      this.dialogSetStockVisible = false;
    },
    handleSetSaleProject() {
      if (this.form && this.form.items) {
        this.form.items.forEach((o) => {
          o.shoPromotionId = this.g_saleproject;
        });
      }
      this.dialogSetSaleProjectVisible = false;
    },
    handleChangeAudited() {
      this.$emit("change");
    },
  },
};
</script>

