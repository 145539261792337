<template>
  <div style="min-height: 120px;" v-loading="loading">
    <template v-if="changeId && detail">
      <div class="bold padding-05-0">订单信息变更</div>
      <el-table :data="formChanges" empty-text="暂无订单信息变更" border>
        <el-table-column prop="label" label="变更项" width="120" />
        <el-table-column label="变更前内容">
          <template slot-scope="scope">{{scope.row.formatter ? scope.row.formatter(scope.row.oldValue) : scope.row.oldValue}}</template>
        </el-table-column>
        <el-table-column label="变更后内容">
          <template slot-scope="scope">{{scope.row.formatter ? scope.row.formatter(scope.row.value) : scope.row.value}}</template>
        </el-table-column>
      </el-table>
      <div class="bold padding-05-0">
        <br />订单商品变更
      </div>
      <el-table :data="detail.items" empty-text="暂无商品变更明细" :row-class-name="getRowClassName" border>
        <el-table-column width="40" align="center">
          <i slot-scope="scope" :class="icons[scope.row.changeType]"></i>
        </el-table-column>
        <el-table-column width="140" label="商品编码">
          <template slot-scope="scope">{{scope.row.changeBefore ? scope.row.changeBefore.code : scope.row.changeAfter.code}}</template>
        </el-table-column>
        <el-table-column width="140" label="ERP编码">
          <template slot-scope="scope">{{scope.row.changeBefore ? scope.row.changeBefore.erpCode : scope.row.changeAfter.erpCode}}</template>
        </el-table-column>
        <el-table-column min-width="200" label="商品">
          <div style="line-height: 1.2;" slot-scope="scope">
            <template v-if="scope.row.changeBefore">
              {{scope.row.changeBefore.goodsName}}
              <br />
              {{$goodsSpecConvert(scope.row.changeBefore.specs)}}
            </template>
            <template v-else>
              {{scope.row.changeAfter.goodsName}}
              <br />
              {{$goodsSpecConvert(scope.row.changeAfter.specs)}}
            </template>
          </div>
        </el-table-column>
        <el-table-column width="60">
          <template slot-scope="scope">{{goodsItemTypes[scope.row.changeBefore ? scope.row.changeBefore.itemType : scope.row.changeAfter.itemType].label}}</template>
        </el-table-column>
        <el-table-column label="销售价" width="120">
          <div style="line-height: 1.2;" slot-scope="scope" v-html="getCellText(scope.row, 'realPrice', $price)"></div>
        </el-table-column>
        <el-table-column label="数量" width="80">
          <div style="line-height: 1.2;" slot-scope="scope" v-html="getCellText(scope.row, 'quantity')"></div>
        </el-table-column>
        <el-table-column label="现场提货" width="80" align="center">
          <i slot-scope="scope" :class="(scope.row.changeBefore ? scope.row.changeBefore.sendType === 0 : scope.row.changeAfter.sendType === 0) ? 'el-icon-check' : ''"></i>
        </el-table-column>
        <el-table-column label="交货日期" width="120">
          <div style="line-height: 1.2;" slot-scope="scope" v-html="getCellText(scope.row, 'deliveryDate', formatDate)"></div>
        </el-table-column>
        <el-table-column label="库房" width="120">
          <div style="line-height: 1.2;" slot-scope="scope" v-html="getCellText(scope.row, 'warehouseId', getWarehouse)"></div>
        </el-table-column>
        <el-table-column label="备注" width="160">
          <div style="line-height: 1.2;" slot-scope="scope" v-html="getCellText(scope.row, 'info')"></div>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import { getChangeDetail } from "@/api/shopOrder";
export default {
  props: {
    changeId: String,
    itemId: String,
    warehouses: Array,
  },
  data() {
    const formatDate = function (v) {
      return new Date(v).format("yyyy-MM-dd");
    };
    const formatBoolean = function (v) {
      return v ? "是" : "否";
    };

    return {
      loading: false,
      detail: null,
      formChanges: null,
      icons: {
        ADD: "el-icon-plus",
        UPDATE: "el-icon-edit",
        CANCEL: "el-icon-minus",
      },
      rowClasses: {
        ADD: "el-table--success-row",
        UPDATE: "",
        CANCEL: "el-table--danger-row",
      },
      goodsItemTypes: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
        simple: { label: "出样", type: "info" },
      },
      formKeys: {
        customerName: { label: "客户姓名" },
        customerPhone: { label: "手机号码" },
        purchaseTime: { label: "单据日期", formatter: formatDate },
        fullPaymentShip: { label: "全款发货", formatter: formatBoolean },
        provinceName: { label: "省份" },
        cityName: { label: "城市" },
        districtName: { label: "区/县" },
        customerAddress: { label: "详细地址" },
        salesmanName: { label: "销售人" },
        contractNo: { label: "合同号" },
        couponRemark: { label: "优惠券" },
        source: { label: "订单来源" },
        info: { label: "备注" },
      },
    };
  },
  methods: {
    load() {
      if (this.changeId) {
        this.loading = true;
        getChangeDetail(this.changeId)
          .then((res) => {
            let formChanges = [];
            if (res.changeAfter) {
              res.changeBefore = JSON.parse(res.changeBefore);
              res.changeAfter = JSON.parse(res.changeAfter);
              for (let k in res.changeAfter) {
                if (
                  res.changeAfter.hasOwnProperty(k) &&
                  res.changeAfter[k] !== res.changeBefore[k] &&
                  this.formKeys.hasOwnProperty(k)
                ) {
                  if (
                    (res.changeAfter[k] === null ||
                      res.changeAfter[k] === "") &&
                    (res.changeBefore[k] === null || res.changeBefore[k] === "")
                  ) {
                  } else {
                    formChanges.push({
                      key: k,
                      label: this.formKeys[k].label,
                      formatter: this.formKeys[k].formatter,
                      oldValue: res.changeBefore[k],
                      value: res.changeAfter[k],
                    });
                  }
                }
              }
            }
            (res.items || []).forEach((o) => {
              if (o.changeBefore) o.changeBefore = JSON.parse(o.changeBefore);
              if (o.changeAfter) o.changeAfter = JSON.parse(o.changeAfter);
              if (o.changeType === "UPDATE") {
                // 如果是更新，则合并
                for (let k in o.changeAfter) {
                  if (o.changeAfter.hasOwnProperty(k)) {
                    o[`_${k}`] = o.changeBefore[k];
                  }
                }
              }
            });
            this.detail = res;
            this.formChanges = formChanges;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getRowClassName({ row }) {
      return this.rowClasses[row.changeType];
    },
    getCellText(item, key, formatter) {
      let v = item.changeAfter ? item.changeAfter[key] : null,
        ov = item.changeBefore ? item.changeBefore[key] : null;
      if (typeof formatter === "function") {
        if (v != null) v = formatter(v);
        if (ov != null) ov = formatter(ov);
      }
      if (item.changeType === "ADD") return v;
      else if (item.changeType === "CANCEL") return ov;
      else {
        if (v === ov) return v;
        else return `${v}<br /><span class="fc-e through">${ov}</span>`;
      }
    },
    formatDate(v) {
      return new Date(v).format("yyyy-MM-dd");
    },
    getWarehouse(warehouseId) {
      let wName = null;
      if (warehouseId) {
        (this.warehouses || []).forEach((o) => {
          if (warehouseId == o.id) {
            wName = o.name;
          }
        });
      }
      return wName;
    },
  },

  mounted() {
    this.load();
  },
};
</script>