<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" custom-class="custom-dialog-max-width" @closed="handleClosed">
    <template v-if="form">
      <template v-if="editable && !targetDependFormCode">
        <el-form ref="form" :model="form" :rules="rules" label-width="90px" label-suffix=":" label-position="right">
          <div class="h" v-if="form.orderFormId">
            <el-form-item label="商场合同号">
              <div style="width: 180px;">{{form.contractNo}}</div>
            </el-form-item>
            <el-form-item label="销售门店" label-width="100px">
              <div style="width: 280px;">{{form.shopName}}</div>
            </el-form-item>
            <el-form-item label="销售单号" label-width="100px">
              <div style="width: 180px;">{{form.orderFormCode}}</div>
            </el-form-item>
            <el-form-item label="客户姓名">
              <div style="width: 180px;">{{form.buyerName}}</div>
            </el-form-item>
          </div>
          <div class="h" v-else>
            <el-form-item label="商场合同号">
              <el-input v-model="form.contractNo" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item label="销售门店" label-width="100px">
              <quick-select v-model="form.shopId" filterable url="api/shop" value-field="id" display-field="shopName" auto-select-first-option placeholder="选择门店" style="width: 260px;" />
            </el-form-item>
            <el-form-item prop="buyerName" label="客户名称" label-width="100px">
              <el-input v-model="form.buyerName" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="buyerPhone" label="客户电话" label-width="100px">
              <el-input v-model="form.buyerPhone" :maxlength="50" style="width: 180px;" />
            </el-form-item>
          </div>

          <el-form-item label="退货详情">
            <sku-selector request-url="api/shop/salesGoods" :exclude-keys="excludeGoods" retail @submit="handleGoodsAdd" v-if="!form.orderFormId" />
            <el-table :data="form.items" :class="form.orderFormId ? 'table-outdent' : ''">
              <el-table-column prop="code" label="商品编码" min-width="130" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
              <el-table-column label="商品" min-width="240">
                <div style="line-height: 1.5;" slot-scope="scope">
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                </div>
              </el-table-column>
              <template v-if="form.orderFormId">
                <el-table-column key="rel_realPrice" prop="realPrice" label="单价" width="100" align="right" :formatter="$price" />
                <el-table-column key="rel_count" prop="quantity" label="销售数量" width="100" align="center" />
                <el-table-column key="rel_sendcount" prop="sendCount" label="已发货数量" width="100" align="center" />
                <el-table-column key="rel_backCount" prop="_backCount" label="已退货数量" width="100" align="center" />
                <el-table-column key="rel_cancelCount" prop="_cancelCount" label="已取消数量" width="100" align="center" />
                <el-table-column key="rel_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0" :max="scope.row._maxBackCount" :step="1" :precision="2" :controls="false" @change="$checkNaN(scope.row, '_currentBackCount',0)" style="width: 100%;" />
                  </template>
                </el-table-column>
              </template>
              <template v-else>
                <el-table-column key="no_realPrice" prop="right" label="单价" width="160" align="right">
                  <template slot-scope="scope">
                    <price-input v-model="scope.row.realPrice" :min="0" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column key="no_currentBackCount" label="本次退货" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row._currentBackCount" :min="0.01" :max="9999" :step="1" :precision="2" :controls="false" @change="$checkNaN(scope.row, '_currentBackCount',1)" style="width: 100%;" />
                  </template>
                </el-table-column>
              </template>
              <el-table-column prop="info" label="备注" width="150">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" :maxlength="100" />
                </template>
              </el-table-column>
              <template v-if="!form.orderFormId">
                <el-table-column width="50">
                  <div class="row-commands" slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                  </div>
                </el-table-column>
              </template>
            </el-table>
          </el-form-item>

          <div class="h c">
            <el-form-item prop="senderName" label="发货人">
              <el-input v-model="form.senderName" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="senderPhone" label="联系电话" label-width="100px">
              <el-input v-model="form.senderPhone" :maxlength="25" style="width: 180px;" />
            </el-form-item>
          </div>
          <div class="h c">
            <el-form-item label="发货地址" prop="sendProvinceCode">
              <quick-select v-model="form.sendProvinceCode" :label.sync="form.sendProvince" url="api/regionalDict/province" value-field="code" placeholder="选择发货省份" filterable style="width: 180px;" @change="form.sendCityCode = null; form.sendDistrictCode = null;" />
            </el-form-item>
            <el-form-item label-width="10px" prop="sendCityCode" style="width: 180px;">
              <quick-select v-model="form.sendCityCode" :label.sync="form.sendCity" :url="`api/regionalDict/parent/${form.sendProvinceCode}`" value-field="code" placeholder="选择城市" filterable v-if="form.sendProvinceCode" style="width: 100%;" @change="form.sendDistrictCode = null" />
              <el-input disabled placeholder="请先选择发货城市" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="sendDistrictCode" style="width: 180px;">
              <quick-select v-model="form.sendDistrictCode" :label.sync="form.sendDistrict" :url="`api/regionalDict/parent/${form.sendCityCode}`" value-field="code" placeholder="选择地区" filterable v-if="form.sendCityCode" style="width: 100%;" />
              <el-input disabled placeholder="请先选择发货地区" v-else />
            </el-form-item>
            <el-form-item label-width="10px" prop="sendAddress" class="flex">
              <el-input v-model="form.sendAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
          </div>

          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="2" />
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" v-if="editable">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
          <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="form.id">保存并提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-suffix=":" label-position="right">
          <div class="h e sb">
            <div>
              <form-info-item label="商场合同号">{{form.contractNo}}</form-info-item>
              <form-info-item label="退货单号">{{form.formCode}}</form-info-item>
              <form-info-item label="退货时间">{{new Date(form.createAt).format()}}</form-info-item>
              <form-info-item label="退款时间">{{ form.backMoneyTime? new Date(form.backMoneyTime).format():""}}</form-info-item>
              <form-info-item label="收货库房" v-if="form.status === 2">{{form.warehouseName}}</form-info-item>
            </div>
            <div>
              <form-info-item label="销售单号" v-if="form.orderFormId">{{form.orderFormCode}}</form-info-item>
              <form-info-item label="客户">{{form.buyerName}}（{{form.buyerPhone}}）</form-info-item>
              <form-info-item label="发货人">{{form.senderName}}（{{form.senderPhone}}）</form-info-item>
              <form-info-item label="发货地址">{{form.sendProvince}}{{form.sendCity}}{{form.sendDistrict}}{{form.sendAddress}}</form-info-item>
            </div>
          </div>
          <el-table :data="backItems" border empty-text="当前退货单没有任何商品" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column label="商品" min-width="240">
              <div style="line-height: 1.5;" slot-scope="scope">
                <div>{{scope.row.goodsName}}</div>
                <div class="fc-g">{{$goodsSpecConvert(scope.row.specs || scope.row.goodsSpec)}}</div>
              </div>
            </el-table-column>
            <el-table-column prop="realPrice" label="单价" width="100" align="right" :formatter="$price" />
            <template v-if="form.orderFormId">
              <el-table-column prop="quantity" label="销售数量" width="100" align="center" />
              <el-table-column prop="_backCount" label="已退货数量" width="100" align="center" />
              <el-table-column prop="_cancelCount" label="已取消数量" width="100" align="center" />
            </template>
            <el-table-column prop="_currentBackCount" label="本次退货" width="100" align="center" />
            <el-table-column prop="info" label="备注" min-width="150" />
          </el-table>
          <div class="h s">
            <form-info-item class="flex" label="备注">{{form.info}}</form-info-item>
            <form-info-item label="已支付金额" label-width="100px" v-if="form.orderFormId">
              <b v-if="form.isRefund">{{$price((form.orderPayAmount || 0) + (form.realBackMoney || 0))}}</b>
              <b v-else>{{$price(form.orderPayAmount || 0)}}</b>
            </form-info-item>
            <form-info-item label="已退款金额" label-width="100px" v-if="form.isRefund">
              <b>{{$price(form.realBackMoney || 0)}}</b>
            </form-info-item>
            <form-info-item label="应退款金额" label-width="100px" v-else>
              <b>{{$price(form.totalMoney || 0)}}</b>
            </form-info-item>
          </div>
        </el-form>
        <template v-if="auditable">
          <el-divider content-position="left">审批</el-divider>
          <el-form ref="auditForm" :model="auditForm" :rules="auditRules" label-position="top">
            <div class="h e">
              <el-form-item label="审批结果" prop="pass">
                <el-radio-group v-model="auditForm.pass">
                  <el-radio-button :label="true">同意</el-radio-button>
                  <el-radio-button :label="false">不同意</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="收货库房" prop="warehouseId" v-if="auditForm.pass" style="margin-left: 20px;">
                <quick-select v-model="auditForm.warehouseId" filterable url="api/warehouse" placeholder="请选择库房" auto-select-first-option clearable style="width: 200px;" />
              </el-form-item>
              <el-form-item label="审批意见" prop="checkInfo" class="flex" label-width="100px" style="margin-left: 20px;">
                <el-input v-model="auditForm.checkInfo" :maxlength="200" />
              </el-form-item>
              <el-form-item style="margin-left: 20px;">
                <el-button type="primary" :loading="saving || submiting" @click="doAudit">提交审批</el-button>
              </el-form-item>
              <el-form-item style="margin-left: 10px;">
                <el-button @click="dialog = false">取消</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
        <template v-else-if="action === 'refund' && form.isReceive && !form.isRefund && form.status === 2">
          <el-divider content-position="left">退款</el-divider>
          <el-form ref="refundForm" :model="refundForm" :rules="refundRules" label-position="top">
            <div class="h e">
              <el-form-item label="实际退款金额" prop="totalMoney">
                <price-input v-model="refundForm.totalMoney" :min="0" :max="form.orderPayAmount / 100" style="width: 200px" />
              </el-form-item>
              <el-form-item label="退款日期" prop="backMoneyTime" style="margin-left: 20px;">
                <el-date-picker type="date" v-model="refundForm.backMoneyTime" value-format="timestamp" :clearable="false" style="width: 200px" />
              </el-form-item>

              <el-form-item prop="feeType" label="费用类型">
                <el-select v-model="refundForm.feeType" placeholder="请选择费用类型" style="width: 200px;">
                  <el-option label="订单费用" value="orderForm" />
                  <el-option label="运费" value="freight" />
                  <el-option label="维修费" value="repair" />
                  <el-option label="其他" value="other" />
                </el-select>
              </el-form-item>

              <el-form-item prop="payChannel" label="退款渠道" style="margin-left: 20px;">
                <el-select v-model="refundForm.payChannel" filterable placeholder="请选择收款渠道" style="width: 200px;">
                  <el-option label="商场" value="mall" />
                  <el-option label="POS" value="store" />
                  <el-option label="企业" value="enterprise" />
                  <el-option label="APP" value="app" />
                </el-select>
              </el-form-item>

              <el-form-item prop="payTypeId" label="退款方式" style="margin-left: 20px;">
                <quick-select v-model="refundForm.payTypeId" filterable url="api/shopPayType" placeholder="请选择支付方式" value-field="id" />
              </el-form-item>
              <el-form-item style="margin-left: 20px;">
                <el-button type="primary" :loading="submiting" @click="doRefund">确认退款</el-button>
              </el-form-item>
              <el-form-item style="margin-left: 10px;">
                <el-button @click="dialog = false">取消</el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
        <template v-else-if="action === 'inbound'">
          <el-divider />
          <el-form label-width="80px" label-suffix=":" label-position="right">
            <div class="h c">
              <form-info-item label="当前状态">
                <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
              </form-info-item>
              <form-info-item label="审批意见" label-width="100px" class="flex" v-if="form.status > 1">{{form.checkInfo || "无"}}</form-info-item>
              <div class="flex" v-else></div>
              <el-form-item label="收货库房" prop="warehouseId" style="margin-top:18px" v-if="action === 'inbound' && !form.isReceive && form.status === 2">
                <quick-select v-model="form.warehouseId" filterable url="api/warehouse" placeholder="请选择库房" style="width: 200px;" />
              </el-form-item>
              <div style="width: 50px"></div>
              <el-button type="primary" :loading="submiting" @click="doInbound" v-if="action === 'inbound' && !form.isReceive && form.status === 2">确认并入库</el-button>
              <el-button @click="dialog = false">{{action ? "取消" : "关闭"}}</el-button>
            </div>
          </el-form>
        </template>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  add,
  get,
  edit,
  submit,
  audit,
  stockIn,
  refund
} from "@/api/shopOrderBackForm";

import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";

export default {
  components: { skuSelector },
  props: {
    targetDependFormCode: String
  },
  data() {
    return {
      loading: false,
      saving: false,
      submiting: false,
      dialog: false,
      auditable: false,
      action: null,
      form: null,
      customerQuery: {
        keyword: ""
      },
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待审批"
        },
        {
          type: "success",
          label: "审批通过"
        },
        {
          type: "danger",
          label: "审批拒绝"
        }
      ],
      rules: {
        sendProvinceCode: [{ required: true, message: "请选择省份" }],
        sendCityCode: [{ required: true, message: "请选择城市" }],
        sendDistrictCode: [{ required: true, message: "请选择地区" }],
        sendAddress: [{ required: true, message: "请填写详细地址" }],
        senderName: [{ required: true, message: "请填写发货人" }],
        senderPhone: [{ required: true, message: "请填写发货人联系电话" }],
        buyerName: [{ required: true, message: "请填写客户名称" }],
        buyerPhone: [
          { required: true, message: "请填写客户电话" },
          {
            pattern: /^\d{11}$/gi,
            message: "手机号码格式不正确"
          }
        ]
      },
      auditForm: {
        id: null,
        checkInfo: "",
        pass: true,
        warehouseId: null
      },
      auditRules: {
        warehouseId: [{ required: true, message: "请选择库房" }]
      },
      refundForm: {
        id: null,
        backMoneyTime: new Date().getTime(),
        payChannel: "mall",
        payTypeId: "",
        feeType: "orderForm",
        totalMoney: 0
      },
      refundRules: {
        payChannel: [{ required: true, message: "请选择退款渠道" }],
        feeType: [{ required: true, message: "请选择费用类型" }],
        payTypeId: [{ required: true, message: "请选择退款方式" }],
        backMoneyTime: [{ required: true, message: "请选择退款日期" }],
        totalMoney: [{ required: true, message: "请填写实际退款金额" }]
      }
    };
  },
  computed: {
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.auditable
          ? "审批销售退货单"
          : this.editable
          ? this.form.id
            ? "编辑销售退货单"
            : "新建销售退货单"
          : "查看销售退货单";
      }
      return str;
    },
    excludeGoods() {
      return (this.form.items || []).map(o => {
        return o.goodsId;
      });
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    backItems() {
      if (this.form && this.form.items && this.form.items.length) {
        return this.form.items.filter(o => {
          return o._currentBackCount > 0;
        });
      }
      return [];
    }
  },

  methods: {
    handleClosed() {
      this.form = null;
      this.auditForm.id = null;
      this.auditForm.checkInfo = "";
      this.refundForm.id = null;
    },
    convertForm() {
      let form = null,
        items = [],
        count = 0;
      (this.form.items || []).forEach(o => {
        if (!o._currentBackCount) o._currentBackCount = 0;
        if (o._currentBackCount > 0) {
          count += o._currentBackCount;
          items.push({
            orderFormId: this.form.orderFormId,
            orderFormCode: this.form.orderFormCode,
            orderFormItemId: o.orderFormItemId,
            goodsId: o.goodsId,
            realPrice: o.realPrice,
            count: o._currentBackCount,
            info: o.info
          });
        }
      });
      if (count === 0) {
        this.$alert("请填写要退货的数量。", "系统提示");
      } else {
        form = JSON.parse(JSON.stringify(this.form));
        form.items = items;
      }
      return form;
    },
    doSave(callback, refresh = true) {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          let form = this.convertForm();
          if (form) {
            this.saving = true;
            (this.form.id ? edit : add)(form)
              .then(res => {
                if (res) this.form.id = res.id;
                if (refresh) this.$parent.init();
                this.saving = false;
                if (typeof callback === "function") callback();
                else
                  this.$notify({
                    title: "保存销售退货单成功",
                    type: "success",
                    duration: 2500
                  });
              })
              .finally(_ => {
                this.saving = false;
              });
          }
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm(
          "提交销售退货单后将不能再进行修改，确定要提交吗？",
          "操作确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true
          }
        ).then(res => {
          this.doSave(_ => {
            this.submiting = true;
            submit({ id: this.form.id })
              .then(res => {
                this.$notify({
                  title: "提交销售退货单成功",
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doAudit() {
      if (this.form && this.form.id && this.auditable) {
        this.$refs.auditForm &&
          this.$refs.auditForm.validate().then(_ => {
            this.$confirm(
              `您确定【${
                this.auditForm.pass ? "同意" : "不同意"
              }】该退货单吗？`,
              "操作确认",
              { type: "warning" }
            ).then(_ => {
              this.submiting = true;
              if (!this.auditForm.pass) this.auditForm.warehouseId = null;
              audit(this.auditForm)
                .then(res => {
                  this.$notify({
                    title: "审批销售退货单成功",
                    type: "success",
                    duration: 2500
                  });
                  this.dialog = false;
                  this.$parent.init();
                })
                .finally(_ => {
                  this.submiting = false;
                });
            });
          });
      }
    },

    doInbound() {
      if (
        this.form.status === 2 &&
        this.form.id &&
        this.form.warehouseId &&
        !this.form.isReceive
      ) {
        this.submiting = true;
        stockIn({
          id: this.form.id,
          warehouseId: this.form.warehouseId
        })
          .then(res => {
            this.$notify({
              title: "确认退货并入库成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.submiting = false;
          });
      }
    },
    doRefund() {
      if (this.form.status === 2 && this.form.id && !this.form.isRefund) {
        this.$refs.refundForm.validate().then(_ => {
          this.submiting = true;
          refund(this.refundForm)
            .then(res => {
              this.$notify({
                title: "退款成功",
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              this.$parent.init();
            })
            .finally(_ => {
              this.submiting = false;
            });
        });
      }
    },
    generateByOrder(order) {
      let _error = _ => {
        this.$alert(
          "未能获取零售单信息，可能是因为该零售单不存在，或者已经进入退货流程或已完成退货。",
          "系统提示",
          {
            type: "warning"
          }
        ).then(_ => {
          this.$parent.add && this.$parent.add();
          this.dialog = false;
        });
      };
      this.loading = true;
      request({
        url: "api/shop/form/" + order.id,
        method: "get"
      })
        .then(res => {
          if (res) {
            let items = (res.items || []).map(o => {
              let _no = {
                id: this.$uuid(),
                orderFormId: order.id,
                orderFormCode: order.formCode,
                orderFormItemId: o.id,
                code: o.code,
                barcode: o.barcode,
                erpCode: o.erpCode,
                goodsId: o.goodsId,
                goodsSpec: o.specs,
                goodsName: o.goodsName,
                goodsBrandId: o.brandId,
                goodsBrandName: o.brandName,
                goodsSeriesId: o.seriesId,
                goodsSeriesName: o.seriesName,
                goodsCategoryId: o.goodsCategoryId,
                goodsCategoryName: o.goodsCategoryName,
                price: o.price,
                realPrice: o.realPrice,
                quantity: o.quantity || 0,
                info: "",
                sendCount: o.sendCount || 0,
                _backCount: o.backCount || 0,
                _currentBackCount: o.currentBackCount || 0,
                _cancelCount: o.cancelCount || 0
              };
              _no._maxBackCount = _no.sendCount;
              if (_no._maxBackCount < 0) _no._maxBackCount = 0;
              return _no;
            });
            this.form = {
              items: items,
              info: "",
              status: 0,
              backType: 1,
              orderFormId: order.id,
              orderFormCode: order.formCode,
              shopName: order.shopName,
              buyerId: order.customerId,
              buyerName: order.customerName,
              senderName: order.customerName,
              senderPhone: order.customerPhone,
              sendProvinceCode: order.provinceCode,
              sendProvince: order.provinceName,
              sendCityCode: order.cityCode,
              sendCity: order.cityName,
              sendDistrictCode: order.districtCode,
              sendDistrict: order.districtName,
              sendAddress: order.customerAddress,
              contractNo: order.contractNo,
              buyerPhone: order.customerPhone
            };
          } else {
            _error();
          }
        })
        .catch(_error)
        .finally(_ => {
          this.loading = false;
        });
    },
    resetForm(form = null, order = null, auditable = false, action = null) {
      this.auditable = auditable;
      this.action = action;
      if (form) {
        this.auditForm.id = form.id;
        this.refundForm.id = form.id;
        this.refundForm.totalMoney = form.totalMoney;
        this.loading = true;
        get(form.id)
          .then(res => {
            if (res.orderFormId) {
              (res.orderItems || []).forEach(o => {
                o.orderFormItemId = o.id;
                o.sendCount = o.sendCount || 0;
                o._backCount = o.backCount || 0;
                o._currentBackCount = o.currentBackCount || 0;
                o._cancelCount = o.cancelCount || 0;
                o._maxBackCount = o.sendCount;
                if (o._maxBackCount < 0) o._maxBackCount = 0;
              });
              res.items = res.orderItems;
            } else {
              res.orderPayAmount = res.totalMoney;
              (res.items || []).forEach(o => {
                o._currentBackCount = o.count || 0;
              });
            }
            delete res.orderItems;
            this.form = res;
          })
          .finally(_ => {
            this.loading = false;
          });
      } else if (order) {
        this.generateByOrder(order);
      } else {
        this.form = {
          items: [],
          info: "",
          status: 0,
          backType: 1,
          orderFormId: null,
          orderFormCode: null,
          shopId: null,
          buyerId: null,
          buyerName: null,
          senderName: null,
          senderPhone: null,
          sendProvinceCode: null,
          sendProvince: null,
          sendCityCode: null,
          sendCity: null,
          sendDistrictCode: null,
          sendDistrict: null,
          sendAddress: null,
          buyerName: null,
          buyerPhone: null
        };
      }
      this.dialog = true;
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach(o => {
          ds.push({
            id: this.$uuid(),
            orderFormId: null,
            orderFormCode: null,
            orderFormItemId: null,
            code: o.code,
            barcode: o.barcode,
            erpCode: o.erpCode,
            goodsId: o.id,
            goodsSpec: o.specs,
            goodsName: o.name,
            goodsBrandId: o.brandId,
            goodsBrandName: o.brandName,
            goodsSeriesId: o.seriesId,
            goodsSeriesName: o.seriesName,
            goodsCategoryId: o.categoryId,
            goodsCategoryName: o.categoryName,
            price: o.realPrice,
            realPrice: o.realPrice,
            quantity: o.quantity || 0,
            info: "",
            _currentBackCount: 1
          });
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex(o => {
          return o.id === row.id;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    }
  }
};
</script>
