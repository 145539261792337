<template>
  <div>
    <el-table :data="store" border empty-text="暂无变更记录" v-loading="loading">
      <el-table-column type="expand" width="50">
        <template slot-scope="scope">
          <change-detail :change-id="scope.row.id" :warehouses="warehouses" style="margin: 0 10px 0 60px;" />
        </template>
      </el-table-column>
      <el-table-column prop="formCode" label="变更单号" />
      <el-table-column prop="createAt" label="变更时间" width="160" :formatter="r => { return new Date(r.createAt).format(); }" />
      <el-table-column prop="createBy" label="变更人" width="120" />
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <dot :type="status[scope.row.status].type" same>{{status[scope.row.status].label}}</dot>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="50" align="right">
        <div class="row-commands" slot-scope="scope">
          <el-button v-permission="['RETAILMANAGE_CHANGE_AUDIT']" size="mini" type="text" @click="toAudit(scope.row)" v-if="scope.row.status===0">审核</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="audit.visible" title="变更审核" append-to-body width="560px" @closed="audit.form = null">
      <template v-if="audit.form">
        <el-form :model="audit.form" label-position="right" label-width="5rem" label-suffix=":">
          <el-form-item label="审核结果" prop="status">
            <el-radio-group v-model="audit.form.status">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核意见" prop="remark">
            <el-input type="textarea" v-model="audit.form.remark" rows="3" maxlength="100" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="audit.form.visible = false">取 消</el-button>
          <el-button type="primary" :loading="audit.form.processing" @click="doAudit">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getChangeRecord, auditChange } from "@/api/shopOrder";
import changeDetail from "./changeDetail";

export default {
  components: { changeDetail },
  props: {
    orderId: String,
    warehouses: Array,
  },
  data() {
    return {
      loading: false,
      store: null,
      current: null,
      status: [
        { id: 0, label: "待审核", type: "gray" },
        { id: 1, label: "审核通过", type: "success" },
        { id: 2, label: "审核未通过", type: "danger" },
      ],
      audit: {
        current: null,
        visible: false,
        processing: false,
        form: null,
      },
    };
  },
  watch: {
    orderId: {
      immediate: true,
      handler: "load",
    },
  },
  methods: {
    load() {
      if (this.orderId) {
        this.loading = true;
        getChangeRecord(this.orderId)
          .then((res) => {
            (res || []).forEach((o) => {
              o.items = [];
            });
            this.store = res;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toAudit(bill) {
      if (bill) {
        this.audit.current = bill;
        this.audit.form = {
          id: bill.id,
          status: 1,
          remark: "",
        };
        this.audit.visible = true;
      }
    },
    doAudit() {
      if (this.audit.current) {
        this.audit.processing = true;
        auditChange(this.audit.form)
          .then((res) => {
            this.$message.success("变更审核成功");
            this.audit.visible = false;
            this.load();
            this.$emit("change-audited");
          })
          .finally(() => {
            this.audit.processing = false;
          });
      }
    },
  },
};
</script>